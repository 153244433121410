import { useState } from 'react';
import { Divider, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PartnerIcon from '@mui/icons-material/SafetyDivider'
import TransactionIcon from "@mui/icons-material/Description";

import { ClientPartnerModel, UserRole } from '../../gql-types.generated';
import { viewerCanEdit } from '../../util/ViewerUtility';
import { CardAvatar, CardChip, CardFooter, ClickableRootCard, RootCardHeader } from '../../util/SharedStyles';

interface ClientTradingPartnerListItemProps {
    viewerRole: UserRole | undefined;
    clientPartner: ClientPartnerModel;
    clickHandler: (id: string | undefined) => void | undefined;
    deleteClientPartner: (id: string) => void;
}

const ClientTradingPartnerListItem: React.FC<ClientTradingPartnerListItemProps> = (props: ClientTradingPartnerListItemProps) => {
    const { viewerRole, clientPartner, clickHandler, deleteClientPartner } = props
    const { id, totalTransactions, partner } = clientPartner;
    const { name, partnerCode, ediStandards } = partner;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const cardClick = () => {
        if (id && clickHandler) {
            clickHandler(id);
        }
    };

    let chipContainerJustify = "flex-end";
    let transactionElement = null;
    if (totalTransactions && totalTransactions > 0) {
        chipContainerJustify = "space-between";
        transactionElement = (
            <Grid container item direction="row" gap={'4px'} justifyContent="flex-start" alignItems="center" xs={'auto'}>
                <TransactionIcon fontSize='small' />
                <Typography>Transactions: {totalTransactions}</Typography>
            </Grid>
        )
    }

    let standardChip;
    if (ediStandards && ediStandards[0] && ediStandards[0].name) {
        standardChip = <CardChip label={ediStandards[0].name} />
    }
    let chipsElement = (
        <Grid item xs={'auto'}>
            {standardChip}
        </Grid>
    )

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // this button could be inside of a card action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event.stopPropagation();

        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (event: React.MouseEvent<any>) => {
        // this button could be inside of a card action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event.stopPropagation();

        let buttonDataSet = (event.target as HTMLElement).dataset;

        if (buttonDataSet.operation === "delete") {
            if (id) {
                deleteClientPartner(id);
            }
        }
        setAnchorEl(null);
    };

    const canEdit = viewerCanEdit(viewerRole);
    const listItemAction = (
        (totalTransactions === 0 && canEdit) ?
            < IconButton
                aria-label="options-button"
                id="options-button"
                onClick={handleMenuClick}
            >
                <MoreVertIcon />
            </IconButton >
            :
            null
    );

    return (
        <ClickableRootCard variant="outlined" onClick={cardClick}>
            <RootCardHeader
                avatar={
                    <CardAvatar alt="Trading Partner">
                        <PartnerIcon />
                    </CardAvatar>
                }
                title={name}
                titleTypographyProps={{ fontSize: '18px' }}
                subheader={partnerCode}
                subheaderTypographyProps={{ variant: 'caption' }}
                action={listItemAction}
            />
            <Divider light />
            <CardFooter justify={chipContainerJustify}>
                <>
                    {transactionElement}
                    {chipsElement}
                </>
            </CardFooter>
            <Menu
                id="card-options-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >

                <MenuItem
                    data-operation="delete"
                    onClick={handleMenuClose}
                >
                    Delete
                </MenuItem>
            </Menu>
        </ClickableRootCard>
    );
};

export default ClientTradingPartnerListItem;