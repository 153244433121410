import { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { DateTime } from 'luxon';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTimePicker } from "@mui/x-date-pickers";
import { Checkbox, ListItemText, Stack, Grid, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, styled } from '@mui/material';
import { BlankMenuItem, FilterBarCheckbox } from '../../util/SharedStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Viewer } from "../../util/Constants";
import { capitalizeWithUnderscoreRemoval, PremadeDocumentArchiveFilterSet } from '../../util/Common';
import { fetchClientNameSearchResults } from '../../features/Clients/ClientsActions';
import { fetchClientNameSearchResultsSuccess, selectClientNameSearchResults } from '../../features/Clients/ClientsSlice';
import { fetchTradingPartnerNameSearchResults } from '../../features/TradingPartners/TradingPartnersActions';
import { fetchTradingPartnerNameSearchResultsSuccess, selectTradingPartnerNameSearchResults } from '../../features/TradingPartners/TradingPartnersSlice';
import { DocumentAcknowledgementStatus } from '../../gql-types.generated';
import FilterBar from "./FilterBar";
import AutocompleteFilterField from '../fields/AutocompleteFilterField';

const DatePickerWrapper = styled('div')((props) => ({
    width: '100%',
}));

interface ArchivesFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    viewer: Viewer | undefined;
    premadeSet?: PremadeDocumentArchiveFilterSet; 
    onClose: () => void;
    onFilterChanged: (
        filterClient: string | undefined, 
        filterIO?: string | undefined, 
        filterDocType?: string | undefined,
        filterPartner?: string | undefined,
        filterControlNum?: string | undefined,
        filterFunctionalGroupControlNum?: string | undefined,
        filterTransactionControlNum?: string | undefined,
        filterPONum?: string | undefined,
        filterFileNameVal?: string | undefined,
        filterAcknowledgmentStatuses?: string[] | undefined,
        filterCreatedDateFrom?: DateTime | undefined,
        filterCreatedDateTo?: DateTime | undefined,
        filterWasNotificationSent?: boolean | undefined,
        filterIsNotificationRequired?: boolean | undefined,
        filterErpOrderNum?: string | undefined
    ) => void;
}

const DocumentArchivesFilterBar: React.FC<ArchivesFilterBarProps> = props => {
    const { id, loading = false, visible = false, viewer, premadeSet, onFilterChanged } = props;
    const dispatch = useAppDispatch();
    const [filterClientName, setFilterClientName] = useState<string | undefined>();
    const [searchClientName, setSearchClientName] = useState<string>('');
    const [filterDirection, setFilterDirection] = useState<string | undefined>();
    const [filterDocType, setFilterDocType] = useState<string | undefined>();
    const [filterPartnerName, setFilterPartnerName] = useState<string | undefined>(undefined);
    const [searchPartnerName, setSearchPartnerName] = useState<string>('');
    const [filterControlNumber, setFilterControlNumber] = useState<string | undefined>(undefined);
    const [filterFunctionalGroupControlNumber, setFilterFunctionalGroupControlNumber] = useState<string | undefined>(undefined);
    const [filterTransactionControlNumber, setFilterTransactionControlNumber] = useState<string | undefined>(undefined);
    const [filterPurchaseOrder, setFilterPurchaseOrder] = useState<string | undefined>(undefined);
    const [filterErpOrderNumber, setFilterErpOrderNumber] = useState<string | undefined>(undefined);
    const [filterFileName, setFilterFileName] = useState<string | undefined>(undefined);
    const [filterStatuses, setFilterStatuses] = useState<string[]>([]);
    const [filterDateFrom, setFilterDateFrom] = useState<DateTime | undefined>(undefined);
    const [filterDateTo, setFilterDateTo] = useState<DateTime | undefined>(undefined);
    const [filterWasNotificationSent, setFilterWasNotificationSent] = useState<string | undefined>(undefined);
    const [filterIsNotificationRequired, setFilterIsNotificationRequired] = useState<boolean | undefined>(undefined);
    const fieldsDisabled = visible && loading;

    const clientNameSearchResults = useAppSelector(selectClientNameSearchResults);
    const partnerNameSearchResults = useAppSelector(selectTradingPartnerNameSearchResults);

    const statuses = Object.values(DocumentAcknowledgementStatus);

    const getWasNotificationSentFilter = () => {
        return filterWasNotificationSent === undefined ? undefined : filterWasNotificationSent === "true";
    }
    
    const onSearchClick = () => {
        onFilterChanged(filterClientName, filterDirection, filterDocType, filterPartnerName, filterControlNumber, filterFunctionalGroupControlNumber, filterTransactionControlNumber, filterPurchaseOrder, filterFileName, filterStatuses, filterDateFrom, filterDateTo, getWasNotificationSentFilter(), filterIsNotificationRequired, filterErpOrderNumber);
    }

    const onCloseClick = () => {
        clearFilters();
        props.onClose();
        // trigger a new search with client undefined since state of filter values might not be updated yet
        onFilterChanged(undefined);
    };    

    useEffect(() => {
        if (premadeSet) {
            clearFilters();
            if (premadeSet.filterClient) {
                setFilterClientName(premadeSet.filterClient);
            }
            if (premadeSet.filterCreatedDateFrom) {
                setFilterDateFrom(premadeSet.filterCreatedDateFrom);
            }
            if (premadeSet.filterCreatedDateTo) {
                setFilterDateTo(premadeSet.filterCreatedDateTo);
            }
            if (premadeSet.filterControlNum) {
                setFilterControlNumber(premadeSet.filterControlNum);
            }
            if (premadeSet.filterFunctionalGroupControlNum) {
                setFilterFunctionalGroupControlNumber(premadeSet.filterFunctionalGroupControlNum);
            }
            if (premadeSet.filterTransactionControlNum) {
                setFilterTransactionControlNumber(premadeSet.filterTransactionControlNum);
            }
            if (premadeSet.filterPurchaseOrderNum) {
                setFilterPurchaseOrder(premadeSet.filterPurchaseOrderNum);
            }
            if (premadeSet.filterErpOrderNum) {
                setFilterErpOrderNumber(premadeSet.filterErpOrderNum);
            }
            if (premadeSet.filterDocType) {
                setFilterDocType(premadeSet.filterDocType);
            }
            if (premadeSet.filterIO) {
                setFilterDirection(premadeSet.filterIO);
            }
            if (premadeSet.filterPartner) {
                setFilterPartnerName(premadeSet.filterPartner);
            }
            if (premadeSet.filterFileNameVal) {
                setFilterFileName(premadeSet.filterFileNameVal);
            }
            if (premadeSet.hasOwnProperty("filterWasNotificationSent")) {
                setFilterWasNotificationSent(premadeSet.filterWasNotificationSent ? "true" : "false");
            }
            if (premadeSet.filterIsNotificationRequired === true) {
                setFilterIsNotificationRequired(true);
            }

            let statuses: string[] = [];
            if (premadeSet.filterAcknowledgmentStatuses && premadeSet.filterAcknowledgmentStatuses.length) {
                premadeSet.filterAcknowledgmentStatuses.forEach(status => {
                    statuses.push(capitalizeWithUnderscoreRemoval(status, " "));
                });
                setFilterStatuses(statuses);
            }
            // onSearchClick relies on the all the states to set in time,
            // set them above for filterbar visuals
            // but make the call directly with the premade filters
            onFilterChanged(
                premadeSet.filterClient, 
                premadeSet.filterIO, 
                premadeSet.filterDocType, 
                premadeSet.filterPartner, 
                premadeSet.filterControlNum,
                premadeSet.filterFunctionalGroupControlNum,
                premadeSet.filterTransactionControlNum,
                premadeSet.filterPurchaseOrderNum, 
                premadeSet.filterFileNameVal,
                statuses, 
                premadeSet.filterCreatedDateFrom, 
                premadeSet.filterCreatedDateTo,
                premadeSet.filterWasNotificationSent,
                premadeSet.filterIsNotificationRequired,
                premadeSet.filterErpOrderNum
            );
        }
    }, [premadeSet])

    const clearFilters = () => {
        clearClientNameFilter();
        clearDocTypeFilter();
        clearFileNameFilter();
        clearControlNumFilter();
        clearFunctionalGroupControlNumFilter();
        clearTransactionControlNumFilter();
        clearPurchaseOrderFilter();
        clearErpOrderNumberFilter();
        clearPartnerNameFilter();
        setFilterDirection(undefined);
        setFilterStatuses([]);
        setFilterDateFrom(undefined);
        setFilterDateTo(undefined);
        setFilterWasNotificationSent(undefined);
        setFilterIsNotificationRequired(undefined);
    };

    const clearClientNameFilter = () => {
        setFilterClientName(undefined);
        setSearchClientName('');
        dispatch(fetchClientNameSearchResultsSuccess());
    };

    const clearDocTypeFilter = () => {
        setFilterDocType(undefined);
    };
  
    const clearFileNameFilter = () => {
        setFilterFileName(undefined);
    };

    const clearControlNumFilter = () => {
        setFilterControlNumber(undefined);
    };

    const clearFunctionalGroupControlNumFilter = () => {
        setFilterFunctionalGroupControlNumber(undefined);
    };

    const clearTransactionControlNumFilter = () => {
        setFilterTransactionControlNumber(undefined);
    };

    const clearPurchaseOrderFilter = () => {
        setFilterPurchaseOrder(undefined);
    };

    const clearErpOrderNumberFilter = () => {
        setFilterErpOrderNumber(undefined);
    };

    const clearPartnerNameFilter = () => {
        setFilterPartnerName(undefined);
        setSearchPartnerName('');
        dispatch(fetchTradingPartnerNameSearchResultsSuccess());
    };
    
    const onClientNameSearch = (searchValue: string) => {
        // once the delay time has elapsed in the autocomplete component
        // the search will be triggered here to fetch client name search results
        dispatch(fetchClientNameSearchResults(searchValue));
    };

    const onClientNameSearchValueChange = (value: string): void => {
            setSearchClientName(value);
    };

    const onClientNameFilterChange = (value: string): void => {
        setFilterClientName(value);
    };

    const onClientNameFilterBlur = (): void => {
        // filterClientName will only be populated if a user selects a search result,
        // so if it doesn't have a value or hasn't been updated to the search value, 
        // set it to the searchClientName value if it has one
        if (searchClientName && filterClientName !== searchClientName) {
            setFilterClientName(searchClientName);
        }
    };

    const onFileNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setFilterFileName(value);
    };

    const onControlNumFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setFilterControlNumber(value);
    };

    const onFunctionalGroupControlNumFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setFilterFunctionalGroupControlNumber(value);
    };

    const onTransactionControlNumFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setFilterTransactionControlNumber(value);
    };

    const onPurchaseOrderFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setFilterPurchaseOrder(value);
    };

    const onErpOrderNumberFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setFilterErpOrderNumber(value);
    };

    const onPartnerNameSearch = (searchValue: string) => {
        // once the delay time has elapsed in the autocomplete component
        // the search will be triggered here to fetch partner name search results
        dispatch(fetchTradingPartnerNameSearchResults(searchValue));
    };

    const onPartnerNameSearchValueChange = (value: string): void => {
            setSearchPartnerName(value);
    };

    const onPartnerNameFilterChange = (value: string): void => {
        setFilterPartnerName(value);
    };

    const onPartnerNameFilterBlur = (): void => {
        // filterPartnerName will only be populated if a user selects a search result,
        // so if it doesn't have a value or hasn't been updated to the search value, 
        // set it to the searchPartnerName value if it has one
        if (searchPartnerName && filterPartnerName !== searchPartnerName) {
            setFilterPartnerName(searchPartnerName);
        }
    };
        
    const onStatusFilterChange = (event: SelectChangeEvent<typeof filterStatuses>) => {
        const {
            target: { value },
        } = event;
        // On autofill we get a stringified value.
        let nameArray = typeof value === 'string' ? value.split(',') : value;
        setFilterStatuses(nameArray);
    };
    
    const onDateFromFilterChange = (value: DateTime | null) => {
        let newDateFrom = value ?? undefined;
        setFilterDateFrom(newDateFrom);
        setFilterDateTo(newDateFrom?.endOf('month') ?? undefined);
    };
    
    const onDateToFilterChange = (value: DateTime | null) => {
        setFilterDateTo(value ?? undefined);
    };
    
    const onDirectionFilterChange = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;
        let currentValue = value?.length > 0 ? value : undefined;
        setFilterDirection(currentValue);
    };

    const onDocTypeFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let typeValue = event.target.value;
        setFilterDocType(typeValue);
    };

    const onWasNotificationSentFilterChange = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;
        let currentValue = value?.length > 0 ? value : undefined;
        setFilterWasNotificationSent(currentValue);
    };

    const onIsNotificationRequiredFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFilterIsNotificationRequired(event.target.checked);
    };

   // generate direction filter list 
   const getDirectionFilterList = () => {
       
        let items = [];
        const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
        const inboundItem = <MenuItem key="1" value="I">I</MenuItem>;
        const outboundItem = <MenuItem key="2" value="O">O</MenuItem>;
        items.push(blankItem);
        items.push(inboundItem);
        items.push(outboundItem);
        return items;
    };
    
    const getStatusFilterList = () => {
        if (statuses && statuses.length) {
            return (
                statuses.map((status: DocumentAcknowledgementStatus) => {
                    let statusDisplay = capitalizeWithUnderscoreRemoval(status, " ");
                    
                    return (
                        <MenuItem
                            key={status}
                            value={statusDisplay}
                            disabled={fieldsDisabled}
                        >
                            <Checkbox checked={filterStatuses && filterStatuses.indexOf(statusDisplay) > -1} />
                            <ListItemText primary={statusDisplay} />
                        </MenuItem>
                    );
                })
            );
        }
        return null;
    };

    const getWasNotificationSentFilterList = () => {
        let items = [];        
        const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
        const sent = <MenuItem key="1" value="true">Sent</MenuItem>;
        const unsent = <MenuItem key="2" value="false">Not Sent</MenuItem>;
        items.push(blankItem);
        items.push(sent);
        items.push(unsent);
        return items;
    };

    const docTypeFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear document type filter"
                    onClick={clearDocTypeFilter}
                    disabled={fieldsDisabled}
                >
                    {filterDocType && filterDocType.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const fileNameFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear file name filter"
                    onClick={clearFileNameFilter}
                    disabled={fieldsDisabled}
                >
                    {filterFileName && filterFileName.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const controlNumFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear control number filter"
                    onClick={clearControlNumFilter}
                    disabled={fieldsDisabled}
                >
                    {filterControlNumber && filterControlNumber.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const funcGroupControlNumFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear functional group control number filter"
                    onClick={clearFunctionalGroupControlNumFilter}
                    disabled={fieldsDisabled}
                >
                    {filterFunctionalGroupControlNumber && filterFunctionalGroupControlNumber.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const transControlNumFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear transaction control number filter"
                    onClick={clearTransactionControlNumFilter}
                    disabled={fieldsDisabled}
                >
                    {filterTransactionControlNumber && filterTransactionControlNumber.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const purchaseOrderFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear purchase order number filter"
                    onClick={clearPurchaseOrderFilter}
                    disabled={fieldsDisabled}
                >
                    {filterPurchaseOrder && filterPurchaseOrder.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const erpOrderNumFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear erp order number filter"
                    onClick={clearErpOrderNumberFilter}
                    disabled={fieldsDisabled}
                >
                    {filterErpOrderNumber && filterErpOrderNumber.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const DropdownMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '300px',
            },
        },
    };
    
    return (
        <FilterBar id={id} visible={visible} onClose={onCloseClick} onSearch={onSearchClick} barHeight="210px">
            <Stack direction={'column'} justifyContent="center" rowGap={2} width="100%">
                <Grid container item direction={'row'} columnGap={2} justifyContent="center" xs={12}>
                    <Grid item xs={1} lg={2}>
                        <AutocompleteFilterField
                            fieldId="document-archive-filter-client-name"
                            searchTerm={searchClientName}
                            onSearchTermValueChange={onClientNameSearchValueChange}
                            filterValue={filterClientName ?? ''}
                            onFilterValueChange={onClientNameFilterChange}
                            clearIndicatorOnClick={clearClientNameFilter}
                            searchResults={clientNameSearchResults}
                            autoFocus={true}
                            label="Client Name"
                            disabled={fieldsDisabled}
                            onFieldBlur={onClientNameFilterBlur}
                            search={onClientNameSearch}
                        />
                    </Grid>
                    <Grid item xs={1} lg={2}>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePickerWrapper>
                                <DateTimePicker
                                    label="Created Date From"
                                    value={filterDateFrom ?? null}
                                    views={['year','month','day','hours','minutes']}
                                    openTo={'month'}
                                    disabled={fieldsDisabled}
                                    onChange={onDateFromFilterChange}
                                    slotProps={{
                                        textField: {
                                            variant: "standard",
                                            fullWidth: true,
                                            inputProps: {'aria-label': 'date from'}
                                        },
                                    }}
                                    data-cy="document-archive-filter-date-from"
                                ></DateTimePicker>
                            </DatePickerWrapper>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={1} lg={2}>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePickerWrapper>
                                <DateTimePicker
                                    label="Created Date To"
                                    value={filterDateTo ?? null}
                                    views={['year','month','day','hours','minutes']}
                                    openTo={'day'}
                                    minDateTime={filterDateFrom}
                                    disabled={fieldsDisabled}
                                    onChange={onDateToFilterChange}
                                    slotProps={{
                                        textField: {
                                            variant: "standard",
                                            fullWidth: true,
                                            inputProps: {'aria-label': 'date to'}
                                        },
                                    }}
                                    data-cy="document-archive-filter-date-to"
                                ></DateTimePicker>
                            </DatePickerWrapper>
                        </LocalizationProvider>
                    </Grid>      
                    <Grid item xs={1} lg={2}>
                        <AutocompleteFilterField
                            fieldId="document-archive-filter-partner-name"
                            searchTerm={searchPartnerName}
                            onSearchTermValueChange={onPartnerNameSearchValueChange}
                            filterValue={filterPartnerName ?? ''}
                            onFilterValueChange={onPartnerNameFilterChange}
                            clearIndicatorOnClick={clearPartnerNameFilter}
                            searchResults={partnerNameSearchResults}
                            label="Trading Partner Name"
                            disabled={fieldsDisabled}
                            onFieldBlur={onPartnerNameFilterBlur}
                            search={onPartnerNameSearch}
                        />
                    </Grid>
                    <Grid item xs={1} lg={2}>
                        <TextField
                            itemID="document-archive-filter-file-name"
                            fullWidth
                            value={filterFileName ?? ''}
                            label="File Name"
                            disabled={fieldsDisabled}
                            inputProps={{ 'aria-label': 'file name', 'maxLength': 50, }}
                            InputProps={fileNameFilterProps}
                            onChange={onFileNameFilterChange}
                            autoComplete="off"
                            data-cy="document-archive-filter-file-name"
                            variant="standard"
                        />
                    </Grid>
                </Grid>
                <Grid container item direction={'row'} columnGap={2} xs={12} justifyContent="center">
                    <Grid item xs={1} lg={2}>
                        <TextField
                            itemID="document-archive-filter-po-num"
                            fullWidth
                            value={filterPurchaseOrder ?? ''}
                            label="PO Number"
                            disabled={fieldsDisabled}
                            inputProps={{ 'aria-label': 'purchase order number', 'maxLength': 50, }}
                            InputProps={purchaseOrderFilterProps}
                            onChange={onPurchaseOrderFilterChange}
                            autoComplete="off"
                            data-cy="document-archive-filter-po-num"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={1} lg={2}>
                        <TextField
                            itemID="document-archive-filter-control-num"
                            fullWidth
                            value={filterControlNumber ?? ''}
                            label="Control Number"
                            disabled={fieldsDisabled}
                            inputProps={{ 'aria-label': 'control number', 'maxLength': 50, }}
                            InputProps={controlNumFilterProps}
                            onChange={onControlNumFilterChange}
                            autoComplete="off"
                            data-cy="document-archive-filter-control-num"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={1} lg={2}>
                        <TextField
                            itemID="document-archive-filter-func-group-control-num"
                            fullWidth
                            value={filterFunctionalGroupControlNumber ?? ''}
                            label="Functional Group Control Number"
                            disabled={fieldsDisabled}
                            inputProps={{ 'aria-label': 'functional group control number', 'maxLength': 50, }}
                            InputProps={funcGroupControlNumFilterProps}
                            onChange={onFunctionalGroupControlNumFilterChange}
                            autoComplete="off"
                            data-cy="document-archive-filter-func-group-control-num"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={1} lg={2}>
                        <TextField
                            itemID="document-archive-filter-transaction-control-num"
                            fullWidth
                            value={filterTransactionControlNumber ?? ''}
                            label="Transaction Control Number"
                            disabled={fieldsDisabled}
                            inputProps={{ 'aria-label': 'control number', 'maxLength': 50, }}
                            InputProps={transControlNumFilterProps}
                            onChange={onTransactionControlNumFilterChange}
                            autoComplete="off"
                            data-cy="document-archive-filter-transaction-control-num"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={1} lg={2}>
                        <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                            <InputLabel id="document-archive-filter-status-label">Acknowledgement Status</InputLabel>
                            <Select
                                labelId="document-archive-filter-status-label"
                                aria-labelledby="document-archive-filter-status-label"
                                id="document-archive-filter-status"
                                multiple
                                value={filterStatuses ?? []}
                                MenuProps={DropdownMenuProps}
                                onChange={onStatusFilterChange}
                                renderValue={(selected) => selected.join(', ')}
                                data-cy="document-archive-filter-status"
                            >
                                {getStatusFilterList()}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item direction={'row'} columnGap={2} xs={12} justifyContent="center">
                    <Grid item xs={1} lg={2}>
                        <TextField
                            itemID="document-archive-filter-erp-order-num"
                            fullWidth
                            value={filterErpOrderNumber ?? ''}
                            label="ERP Order Number"
                            disabled={fieldsDisabled}
                            inputProps={{ 'aria-label': 'erp order number', 'maxLength': 50, }}
                            InputProps={erpOrderNumFilterProps}
                            onChange={onErpOrderNumberFilterChange}
                            autoComplete="off"
                            data-cy="document-archive-filter-erp-order-num"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={1} lg={2}>
                        <TextField
                            itemID="document-archive-filter-doc-type"
                            fullWidth
                            value={filterDocType ?? ''}
                            label="Type"
                            disabled={fieldsDisabled}
                            inputProps={{ 'aria-label': 'document type', 'maxLength': 50, }}
                            InputProps={docTypeFilterProps}
                            onChange={onDocTypeFilterChange}
                            autoComplete="off"
                            data-cy="document-archive-filter-doc-type"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={1} lg={2}>
                        <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                            <InputLabel id="document-archive-filter-direction-label">I/O</InputLabel>
                            <Select
                                labelId="document-archive-filter-direction-label"
                                aria-labelledby="document-archive-filter-direction-label"
                                id="document-archive-filter-direction"
                                value={filterDirection ?? ''}
                                MenuProps={{
                                    'aria-label': 'transaction direction',
                                }}
                                onChange={onDirectionFilterChange}
                                data-cy="document-archive-filter-direction"
                            >
                                {getDirectionFilterList()}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} lg={2}>
                        <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                            <InputLabel id="document-archive-filter-was-notification-sent-label">Notification Sent</InputLabel>
                            <Select
                                labelId="document-archive-filter-was-notification-sent-label"
                                aria-labelledby="document-archive-filter-was-notification-sent-label"
                                id="document-archive-filter-was-notification-sent"
                                value={filterWasNotificationSent ?? ''}
                                MenuProps={{
                                    'aria-label': 'filter was notification sent',
                                }}
                                onChange={onWasNotificationSentFilterChange}
                                data-cy="document-archive-filter-was-notification-sent"
                            >
                                {getWasNotificationSentFilterList()}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} lg={2} display={"inline-flex"} alignItems={"flex-end"}>
                        <FormControlLabel 
                            control={<FilterBarCheckbox checked={filterIsNotificationRequired ?? false} onChange={onIsNotificationRequiredFilterChange} />} 
                            label="Notification Is Required" 
                            disabled={fieldsDisabled}
                        />
                    </Grid>
                </Grid>
            </Stack>
        </FilterBar>
    );
};

export default DocumentArchivesFilterBar;