import { ReactNode } from "react";
import { Alert, Avatar, Box, Card, CardActions, CardContent, CardHeader, Checkbox, Chip, DialogContent, Grid, IconButton, List, ListItemText, MenuItem, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { styled } from '@mui/system';
import theme from '../Theme';
import { ToastSeverity } from "./Constants";

export const CloseButtonTopRight = styled(IconButton)((props) => ({
    position: 'absolute',
    right: '10px',
    top: '10px',
}));

export const BlankMenuItem = styled(MenuItem)((props) => ({
    height: '36px'
}));

interface paddedDialogProps {
    padding?: string;
    minwidth?: string;
    maxheight?: string;
}
export const PaddedDialogContent = styled(DialogContent)<paddedDialogProps>((props) => ({
    padding: props.padding,
    minWidth: props.minwidth,
    maxHeight: props.maxheight
}));
PaddedDialogContent.defaultProps = {
    padding: '30px',
    minwidth: 'auto',
    maxheight: 'auto'
};

export const RootCard = styled(Card)((props) => ({
    minHeight: '120px',
}));


interface clickableCardProps {
    disabled?: boolean;
}
export const ClickableDashboardCard = styled(RootCard)<clickableCardProps>((props) => ({
    height: '120px',
    width: '100%',
    ...(props.disabled !== true && {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.background.default,
        }
    }),
}));

export const ClickableRootCard = styled(RootCard)<clickableCardProps>((props) => ({
    ...(props.disabled !== true && {
        cursor: 'pointer',
        '&:hover': {
            boxShadow: `0px 0px 0px 2px inset ${theme.palette.primary.main}`,
        }
    }),
}));

export const PlaceholderCard = styled(Card)((props) => ({
    width: 'fit-content',
    margin: 'auto',
}));

export const RootCardHeader = styled(CardHeader)((props) => ({
    display: 'flex',
    overflow: 'hidden',
    '& .MuiCardHeader-content': {
        overflow: 'hidden'
    },
}));

export const DashboardRootCardHeader = styled(RootCardHeader)((props) => ({
    paddingBottom: '0px !important',
}));

export const DetailsRootCardHeader = styled(RootCardHeader)((props) => ({
    padding: '12px 16px 0px 16px !important',
}));

export const DashboardCardContent = styled(CardContent)((props) => ({
    paddingTop: '0px !important',
}));

interface cardFooterProps {
    justify: string;
    children: Element | Element[] | ReactNode;
}

export const CardFooter = styled(CardActions)<cardFooterProps>((props) => ({
    justifyContent: props.justify,
    height: '48px',
    paddingLeft: '16px',
    paddingRight: '24px',
}));

export const CardAvatarInitials = styled(Typography)((props) => ({
    fontSize: '18px',
    fontWeight: 'bold',
}));

interface cardAvatarProps {
    background?: string;
    foreground?: string;
}

export const CardAvatar = styled(Avatar)<cardAvatarProps>((props) => ({
    backgroundColor: props.background,
    color: props.foreground,
    height: '42px',
    width: '42px',
    marginRight: '-6px'
}));

CardAvatar.defaultProps = {
    background: theme.palette.background.icon,
    foreground: theme.palette.grayscale.main
};

export const CustomChip = styled(Chip)((props) => ({
    fontSize: '12px',
    height: '24px'
}));

// TODO: update theme to include more palette options
export const CardChip = styled(CustomChip)((props) => ({
    color: theme.palette.grayscale.main,
    border: `1px solid ${theme.palette.grayscale.main}`,
    backgroundColor: theme.palette.background.paper,
}));

export const StatusChip = styled(Chip)((props) => ({
    fontSize: '12px',
    height: '24px'
}));

export const ActiveStatusChip = styled(StatusChip)((props) => ({
    backgroundColor: theme.palette.success.contrastText,
    color: theme.palette.success.light,
    border: `1px solid ${theme.palette.success.light}`,
}));

export const SuspendedStatusChip = styled(StatusChip)((props) => ({
    backgroundColor: theme.palette.success.contrastText,
    color: theme.palette.warning.light,
    border: `1px solid ${theme.palette.warning.light}`,
}));

export const IntermediateStatusChip = styled(StatusChip)((props) => ({
    backgroundColor: theme.palette.success.contrastText,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
}));

export const MainContentBox = styled(Box)((props) => ({
    width: '100%',
    height: '100%',
    margin: '0',
    paddingBottom: '8px',
}));

export const ReadOnlyGridColumn = styled(Grid)((props) => ({
    width: '412px', 
}));

export const DialogGridColumn = styled(Grid)((props) => ({
    paddingTop: '0px !important', //remove default Grid top padding
    width: '360px'
}));

export const DialogGridWithTopMargin = styled(Grid)((props) => ({
    paddingTop: '0px !important', //remove default Grid top padding 
    marginTop: '16px', // use margin instead of padding
    alignItems: 'flex-start'
}));

export const CardListContentGrid = styled(Grid)((props) => ({
    width: '100% !important', // override default Grid width
    marginTop: '0px !important', //override default Grid top margin
    marginLeft: '-4px !important', //override default Grid left margin
    paddingBottom: '16px',
    paddingRight: '16px',
}));

interface cardListScrollBoxProps {
    scrollheight: string;
    children: Element | Element[] | ReactNode;
}

export const CardListScrollBox = styled('div')<cardListScrollBoxProps>((props) => ({
    width: '100%',
    overflowY: 'auto',
    padding: '10px',
    height: props.scrollheight,
}));


interface gridListScrollBoxProps {
    scrollheight: string;
    children: Element | Element[] | ReactNode;
}

export const DataGridListScrollBox = styled('div')<gridListScrollBoxProps>((props) => ({
    width: '100%',
    padding: '10px',
    height: props.scrollheight,
}));

export const CardListItemWrapperGrid = styled(Grid)((props) => ({
    paddingRight: '12px',
}));

export const WrappedLoadingGrid = styled(Grid)((props) => ({
    paddingTop: '16px',
    width: '100% !important',
    marginLeft: '0px !important',
}));

export const PageTitleToolbarGrid = styled(Grid)((props) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: '0px !important', //remove default Grid top padding
    gap: '16px',
}));

export const DetailsContentBox = styled(Box)((props) => ({
    width: '100%',
    height: 'calc(100% - 70px)',
    margin: '0',
    padding: '16px',
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto',
}));

export const DetailsContentBoxLandscape = styled(DetailsContentBox)((props) => ({
    overflowY: 'hidden'
}));

export const DetailsPanelFullHeightGrid = styled(Grid)((props) => ({
    height: '100%',
}));

export const DetailsPaperContent = styled(Paper)((props) => ({
    width: '100%',
    margin: '12px',
    height: 'auto',
}));

export const DetailsPaperContentLandscape = styled(Paper)((props) => ({
    width: '100%',
    margin: '12px',
    height: '100%',
}));

export const StackPortDetails = styled(Stack)((props) => ({
    height: 'calc(100% - 70px)',
    margin: '0',
    padding: '16px',
    overflowY: 'auto',
}));

export const PortraitTabbedContentGrid = styled(Grid)((props) => ({
    height: '-webkit-fill-available',
}));

export const DetailsCard = styled(Card)((props) => ({
    width: '100%',
    margin: '12px',
}));

export const DetailsCardLandscape = styled(DetailsCard)((props) => ({
    height: '100%',
}));
interface detailsCardLandscapeContentProps {
    scrollheight: string;
    children: Element | Element[] | ReactNode;
}
export const DetailsCardLandscapeContent = styled(CardContent)<detailsCardLandscapeContentProps>((props) => ({
    height: props.scrollheight,
    overflowY: 'auto',
}));

export const DetailsTabContainer = styled(Box)((props) => ({
    width: '100%',
    height: '100%',
}));

// TODO: theme for the border color
export const DetailsTabHeaderWrapper = styled(Box)((props) => ({
    borderBottom: '1px solid lightgray',
}));

export const DetailsTabHeader = styled(Tabs)((props) => ({
    height: '48px',
}));

export const DetailsTabPanelContainer = styled('div')((props) => ({
    height: 'calc(100% - 48px)', //48 should match TabHeader height
}));

export const PlaceholderPaper = styled(Paper)`
    text-align: 'center';
`;

interface tabToolbarProps {
    justify: string;
    noborder?: number;
    children: Element | Element[] | ReactNode;
}

export const TabToolbar = styled(Box)<tabToolbarProps>((props) => ({
    justifyContent: props.justify,
    width: '100%',
    height: '64px',
    display: 'flex',
    padding: '12px',
    ...(!!props.noborder !== true && {
        borderBottom: 'rgba(0,0,0,0.08) solid thin',
    }),
}));

export const TabCardListBox = styled(Box)((props) => ({
    width: '100%',
    height: 'calc(100% - 65px)', //Tab Card Lists all have TabToolbar, so account for height of that and a little more for bottom spacing
    overflowY: 'auto',
}));

export const TabLabel = styled(Typography)((props) => ({
    fontSize: '14px',
}));

export const TabHeader = styled(Tab)((props) => ({
    '&:hover': {
        // Colors taken from mui icon button hovers
        backgroundColor: 'rgba(0,0,0,0.04)!important',
        color: 'rgb(26,77,169)',
    }
}));

export const TabContainer = styled('div')((props) => ({
    width: '100%',
    height: '100%',
}));

export const TabContent = styled('div')((props) => ({
    width: '100%',
    height: 'calc(100% - 64px)', // account for toolbar
}));

export const TabDataGrid = styled(DataGridPro)((props) => ({
    height: '100%',
    border: 'none',
    padding: '12px 20px',
    '& .MuiDataGrid-columnHeaderTitle': {
        fontSize: '12px',
        fontWeight: 'bold',
        color: theme.palette.grayscale.dark,
    },
    '& .MuiDataGrid-columnSeparator': {
        visibility: 'hidden',
    },
    '& .MuiDataGrid-columnHeader:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
        outline: 'none',
    },
    '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: theme.palette.background.selected,
        '&:hover': {
            backgroundColor: theme.palette.background.selected
        }
    },
    '& .MuiDataGrid-row:hover': {
        cursor: 'pointer',
    },
    '& .MuiDataGrid-cell': {
        fontSize: '14px',
        letterSpacing: '0.16px',
        textAlign: 'center'
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-cell:focus-within': {
        outline: 'none',
    },
    '& .ediDataGridCellFirstChild': {
        fontSize: '16px',
    },
    '& .ediDataGridCellLastChild': {
        paddingRight: '4px',
    },
    '& .ediDataGridCellOverflowHidden': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    // ediDataGridLongDescriptionCell allows 2 lines of text and cuts off overflow with ellipsis
    '& .ediDataGridLongDescriptionCell .MuiDataGrid-cellContent': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        whiteSpace: 'break-spaces',
        textAlign: 'start',
    },
    '& .ediDataGridCellDisabled': {
        color: theme.palette.grayscale.light,
        cursor: 'default',
    },
    '& .ediDataGridCellWarning': {
        color: theme.palette.warning.light,
    },
    '& .MuiDataGrid-row:hover > .ediDataGridCellDisabled': {
        backgroundColor: 'white !important',
    },
    '& .MuiDataGrid-actionsCell > .MuiIconButton-root[hidden]': {
        display: 'none'
    },
    '& .ediDataGridWrapCellInnerContent .MuiGrid-root.MuiGrid-container.MuiGrid-item': {
        flexWrap: 'nowrap',
        '& p': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        }
    }
}));

export const TabDataGridNoRowHover = styled(TabDataGrid)((props) => ({
    '& .MuiDataGrid-row:hover': {
        backgroundColor: 'unset !important',
        color: 'unset !important',
        cursor: 'default'
    },
}));

export const MainDataGrid = styled(TabDataGrid)((props) => ({
    '& .MuiDataGrid-columnHeaders': {
        border: 'none',
    },
    '& .MuiDataGrid-row': {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        marginBottom: '6px',
        borderRadius: '4px',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: `0px 0px 0px 2px inset ${theme.palette.primary.main}`
    },
    '& .MuiDataGrid-cell': {
        border: 'none',
    },
    '& .ediDataGridWrapCellContent .MuiDataGrid-cellContent': {
        overflow: 'unset',
        overflowWrap: 'anywhere',
        whiteSpace: 'pre-wrap'
    },
    '& .ediDataGridWrapCellContent-alwaysLeft .MuiDataGrid-cellContent': {
        overflow: 'unset',
        overflowWrap: 'anywhere',
        whiteSpace: 'pre-wrap',
        textAlign: 'left'
    },
    '& .MuiDataGrid-virtualScrollerRenderZone': {
        width: 'auto'
    },
}));

export const MainDataGridNoRowHover = styled(MainDataGrid)((props) => ({
    '& .MuiDataGrid-row:hover': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        cursor: 'default'
    },
}));

export const DialogDataGrid = styled(TabDataGrid)((props) => ({
    padding: '8px 0px !important',
    width: '100%',
    height: '400px',
}));
interface dialogDataGridWrapperProps {
    minwidth?: string;
}
export const DialogDataGridWrapper = styled(Grid)<dialogDataGridWrapperProps>((props) => ({
    minWidth: props.minwidth,
}));
DialogDataGridWrapper.defaultProps = {
    minwidth: '350px',
};

export const DialogDynamicListToolbar = styled(TabToolbar)((props) => ({
    padding: '0 !important',
    alignItems: 'center',
    height: '48px'
}));

export const DialogDynamicListContainer = styled(TabContainer)((props) => ({
    maxHeight: '256px',
}));

export const DialogDynamicListContent = styled(TabContent)((props) => ({
    height: 'calc(100% - 48px)',
}));

export const DialogDynamicListHeader = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.grayscale.dark
}));

interface gridProps {
    height: string;
}
export const DialogDynamicListDataGrid = styled(TabDataGridNoRowHover)<gridProps>((props) => ({
    padding: '0 0 12px 0 !important',
    height: props.height
}));

export const CountBox = styled(Box)((props) => ({
    backgroundColor: theme.palette.grayscale.main,
    color: '#ffffff',
    borderRadius: "3px",
    height: '14px',
    width: '20px',
    fontSize: '12px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

// Dom can't handle a boolean being passed to a custom property
// transient-props also throw errors
// expand={+value} will turn boolean value into a number and can be parsed.
// 0(false) 1(true)
interface ExpandMoreProps {
    expand: number;
};

export const ExpandMore = styled(IconButton)<ExpandMoreProps>((props) => ({
    transform: props.expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
}));

export const IconAvatar = styled(Avatar)((props) => ({
    backgroundColor: theme.palette.action.hover,
    width: '36px',
    height: '36px',
}));

export const DetailWrapValue = styled(Typography)((props) => ({
    overflowWrap: 'anywhere',
}));

export const TimeSinceText = styled(ListItemText)((props) => ({
    flex: '0 0 auto',
}));

export const ListCard = styled(Card)((props) => ({
    maxHeight: 720,
}));

export const ListCardContentScrolling = styled(CardContent)((props) => ({
    maxHeight: 656, //720 - 64(card header)
    overflowY: 'auto',
}));

export const TabbedListCard = styled(Card)((props) => ({
    width: 400,
    maxHeight: 448,
}));

export const TabbedListCardTabList =  styled(List)((props) => ({
    maxHeight: 400, //448 card height - 48(tabheader),
    overflowY: 'auto',
}));

// HSC - Home Screen Card
export const HSCContentScrolling = styled(CardContent)((props) => ({
    maxHeight: 504, //TBD (adjust if the files using this style end up active again) - 64(card header) - 152(cards row),
    overflowY: 'auto',
}));

export const HSCTabContentScrolling = styled(CardContent)((props) => ({
    maxHeight: 456, //504 (adust based on what height in HSCContentScrolling ends up being) - 48(tabheader),
    overflowY: 'auto',
}));

export const HSCPrimaryTextNoWrap = styled('span')((props) => ({
    // copied from secondary span created by ListItemText
    margin: 0,
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    display: 'block',
    // added to stop text wrapping to another line
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}));

export const GeneralMessagePaper = styled(Paper)((props) => ({
    textAlign: 'center',
    padding: '20px',
    margin: '20px',
    height: 'max-content',
    fontSize: '1.25em',
}));

export const Toast = styled(Alert)((props) => ({
    width: '100%',
    height: 'max-content',
    fontWeight: 'bold',
    ...(props.severity === ToastSeverity.Error && {
        color: theme.palette.toastError.main,
        background: theme.palette.toastError.light,
        '& .MuiAlert-icon': {
            color: theme.palette.toastError.main,
        }
    }),
    ...(props.severity === ToastSeverity.Info && {
        color: theme.palette.toastInfo.main,
        background: theme.palette.toastInfo.light,
        '& .MuiAlert-icon': {
            color: theme.palette.toastInfo.main,
        }
    }),
    ...(props.severity === ToastSeverity.Success && {
        color: theme.palette.toastSuccess.main,
        background: theme.palette.toastSuccess.light,
        '& .MuiAlert-icon': {
            color: theme.palette.toastSuccess.main,
        }
    }),
    ...(props.severity === ToastSeverity.Warning && {
        color: theme.palette.toastWarning.main,
        background: theme.palette.toastWarning.light,
        '& .MuiAlert-icon': {
            color: theme.palette.toastWarning.main,
        }
    }),
}));

export const GridItemMinWidth = styled(Grid)((props) => ({
    minWidth: '280px',
}));

interface scrollableGridProps {
    scrollheight?: string;
    children: Element | Element[] | ReactNode;
}
export const ScrollableGrid = styled(Grid)<scrollableGridProps>((props) => ({
    height: props.scrollheight,
    width: '100%',
    paddingRight: '32px',
    paddingBottom: '32px',
    margin: '0px',
    overflow: 'auto',
}));
ScrollableGrid.defaultProps = {
    height: '100%',
};

export const FullHeightStack = styled(Stack)((props) => ({
    height: '100%',
    width: '100%',
    padding: '32px',
    margin: '0px',
    overflow: 'auto',
}));

export const FilterBarCheckbox = styled(Checkbox)((props) => ({
    padding: '0',
    paddingLeft: '9px',
    paddingRight: '9px'
}));
