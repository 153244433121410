import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import DownloadIcon from "@mui/icons-material/Download";
import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Stack, Switch, TextField, Typography } from "@mui/material";
import { DialogGridWithTopMargin, DialogGridColumn, ReadOnlyGridColumn } from '../../util/SharedStyles';
import { UpsertNotificationFormatterInput, AddNotificationFormatterTransactionsInput, NotificationFormatterBodyType, RequestResult, DeleteByIdPayload } from '../../gql-types.generated';
import { fetchNotificationFormatterById } from '../../features/NotificationFormatters/NotificationFormattersActions';
import { selectNotificationFormatter, clearNotificationFormatterByIdState } from '../../features/NotificationFormatters/NotificationFormattersSlice';
import AddEditDialog from "./AddEditDialog";
import NotificationFormatterDialogTransactionList from '../lists/transactions/NotificationFormatterDialogTransactionList';
import FileDropZone from '../FileDropZone';
import { getBase64, downloadDocument } from "../../util/Common";
import { maxFileSize, Viewer } from "../../util/Constants";

interface NotificationFormatterDialogProps {
    isOpen: boolean;
    viewerRole: Viewer | undefined;
    isReadOnly?: boolean;
    isRefreshing?: boolean;
    id?: string | undefined;
    onClose: () => void;
    onCancel: () => void;
    onSave: (inputData: UpsertNotificationFormatterInput) => void;
    onAddTransactions: (
        inputData: AddNotificationFormatterTransactionsInput,
    ) => void;
    onDeleteTransaction: (
        id: string,
    ) => void;
    clearError: () => void;
    error?: Error | undefined;
    saveResult?: RequestResult | undefined;
    addTransactionsResult?: RequestResult | undefined;
    deleteTransactionStatus?: DeleteByIdPayload;
}

const NotificationFormatterDialog: React.FC<NotificationFormatterDialogProps> = props => {
    const { isOpen, viewerRole, isRefreshing, isReadOnly = false, id, onClose, onCancel, onSave, onAddTransactions, onDeleteTransaction, clearError, error, saveResult, addTransactionsResult, deleteTransactionStatus } = props;
    const dispatch = useAppDispatch();
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [description, setDescription] = useState<string>('');
    const [formatterType, setFormatterType] = useState<string | undefined>(undefined);
    const [templateString, setTemplateString] = useState<string | undefined>(undefined);
    const [existingTemplateFile, setExistingTemplateFile] = useState<File | undefined>(undefined);
    const [templateFileContents, setTemplateFileContents] = useState<string | undefined>(undefined);
    const [subject, setSubject] = useState<string | undefined>(undefined);
    const [body, setBody] = useState<string | undefined>(undefined);
    const [bodyType, setBodyType] = useState<NotificationFormatterBodyType>(NotificationFormatterBodyType.Html);
    const [isAsAttachment, setIsAsAttachment] = useState<boolean>(false);
    const [isForError, setIsForError] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false); // Submitted state of the form
    const [isViewMode, setIsViewMode] = useState<boolean>(false);
    const [fileType, setFileType] = useState<string | undefined>(undefined);

    const formatter = useAppSelector(selectNotificationFormatter);
    
    const templateFileName = "formatter-template.xslt";
    const templateFileMimeType = "text/xml";

    useEffect(() => {
        // get formatter on dialog entry with passed in id
        if (id) {
            dispatch(fetchNotificationFormatterById(id));
        }
        // on page exit, set state to defaults (undefined)
        return () => {
            dispatch(clearNotificationFormatterByIdState());
        }
    }, [id]);

    useEffect(() => {
        if (id && (addTransactionsResult === RequestResult.Success || deleteTransactionStatus?.result === RequestResult.Success)) {
            dispatch(fetchNotificationFormatterById(id));
        }
    }, [addTransactionsResult, deleteTransactionStatus?.result]);
   
    const setFromFormatter = useCallback(() => {
        if (formatter) {
            setDescription(formatter.description);
           
            if (formatter.formatterType) {
                setFormatterType(formatter.formatterType);
            }
            if (formatter.template !== undefined && formatter.template !== null) {
                setTemplateString(formatter.template);
                // this File will be used for display purposes only, and we are not saving the document title.  
                // template could be quite large, so using a small string (again display only).
                // Actual data will be used when saving.
                let fileName = templateFileName;
                if (formatter.fileType) {
                    fileName = "formatter-template" + formatter.fileType;
                    setFileType(formatter.fileType);
                }
                let templateFile = new File(["Sm9lIG1hZGUgdGhpcyBzYW1wbGUgc3RyaW5nLCB5b3Ugc2hvdWxkbid0IGJlIHNlZWluZyBpdC4gIA=="], fileName, {type:templateFileMimeType});
                setExistingTemplateFile(templateFile);
            }
            if (formatter.subject) {
                setSubject(formatter.subject);
            }
            if (formatter.body) {
                setBody(formatter.body);
            }
            if (formatter.bodyType) {
                setBodyType(formatter.bodyType);
            }
            if (formatter.asAttachment !== undefined && formatter.asAttachment !== null) {
                setIsAsAttachment(formatter.asAttachment as boolean);
            }
            if (formatter.isForError !== undefined && formatter.isForError !== null) {
                setIsForError(formatter.isForError as boolean);
            }
        } else {
            setToDefaults();
        }
    }, [formatter]);
    
    useEffect(() => {
        // set submitted to false and clear fields when dialog is closed
        if (isOpen) {
            setFromFormatter();
        } else {
            setToDefaults();
            setSubmitted(false);
            setIsFormDirty(false);
        }
    }, [isOpen, setFromFormatter]);

    useEffect(() => {
        // upon successful save during an edit from viewMode, 
        // need to set submitted back to false and revert back to viewMode
        if (saveResult === RequestResult.Success) {
            if (isReadOnly) {
                setSubmitted(false);
                setIsFormDirty(false);
                setIsViewMode(true);
            }
        }
    }, [saveResult, isReadOnly]);

    useEffect(() => {
        // set initial mode
        setIsViewMode(isReadOnly);
    }, [isReadOnly]);

    const setToDefaults = () => {
        setDescription('');
        setFormatterType(undefined);
        setSubject(undefined);
        setBody(undefined);
        setBodyType(NotificationFormatterBodyType.Html);
        setIsAsAttachment(false);
        setIsForError(false);
        setTemplateString(undefined);
        setExistingTemplateFile(undefined);
        setTemplateFileContents(undefined);
    };
    
    const onError = () => {
        setSubmitted(false);
    }

    const isFormValid = () => {
        return isFormDirty && description?.trim().length > 0;
    };

    const submitForm = () => {
        setSubmitted(true);
        let template = templateFileContents ? templateFileContents : templateString;
        
        onSave({
            id,
            description, 
            formatterType,
            template,
            subject,
            body,
            bodyType,
            asAttachment: isAsAttachment,
            isForError,
            fileType
        });
    };

    const viewModeEditForm = () => {
        // turn off viewMode to allow for editing
        setIsViewMode(false);
    };

    const onEditCancel = () => {
        if (isReadOnly) {
            // revert back to viewMode instead of closing
            setIsViewMode(true);
            // refresh the data from the parent in case any field changed during edit
            onCancel();
        }
        else {
            // close as usual
            onClose();
        }
    };

    // onChange handlers
    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };
    const onFormatterTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setFormatterType(event.target.value);
    };
    const onSubjectChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setSubject(event.target.value);
    };
    const onBodyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setBody(event.target.value);
    };
    const onBodyTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setBodyType(event.target.value as NotificationFormatterBodyType);
    };
    const onAsAttachmentChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setIsAsAttachment(event.target.checked);
    };
    const onIsForErrorChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setIsForError(event.target.checked);
    };

    const getTemplateFileContents = async (file: File) => {

        let fileContentBase64 = "";

        // convert file to base64 string to pass to server
        await getBase64(file).then(result => {
            let resultParts = result?.split(',');
            if (resultParts) {
                fileContentBase64 = resultParts[1] ?? '';
            }
        });

        return fileContentBase64;
    };

    const onFileUploadChange = async (filesToUpload: File[]) => {
        setIsFormDirty(true);
        if (filesToUpload && filesToUpload.length > 0) {
            // only 1 file allowed, so just get contents of first file
            let tFile = filesToUpload[0];
            let fileContents = await getTemplateFileContents(tFile);
            setTemplateFileContents(fileContents);
            let pos = tFile.name.lastIndexOf('.');
            let type = tFile.name.substring(pos);
            setFileType(type);
        } else {
            // when no file, make sure any previous file fields are cleared
            setTemplateFileContents(undefined);
            setTemplateString(undefined);
            setFileType(undefined);
        }
    };
    
    const onDownloadTemplateClick = () => {
        // call common function to download
        downloadDocument(templateString, templateFileName, templateFileMimeType, true);
    }
    
    const getTransactionsList = () => {
        return (
            <NotificationFormatterDialogTransactionList 
                viewerRole={viewerRole?.role} 
                formatter={formatter} 
                isLoading={submitted || isRefreshing}
                addTransactions={onAddTransactions}
                deleteTransaction={onDeleteTransaction} 
                addTransactionsResult={addTransactionsResult}
                deleteTransactionStatus={deleteTransactionStatus}
                error={error}
                clearError={clearError} 
            />
        );
    };

    // readOnly fields are needed when in viewMode
    const getViewFields = () => {
        return (
            <Grid container spacing={2} item xs={12}>
                <DialogGridWithTopMargin container item columns={{ xs: 1, sm: 2 }} spacing={2} xs={12}>
                    <DialogGridColumn id="detailsColumn1" container item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                <ReadOnlyGridColumn item >
                                    <Typography variant='caption' aria-label='description'>Description</Typography>
                                    <Typography variant='body1' >{description}</Typography>
                                </ReadOnlyGridColumn>
                                <ReadOnlyGridColumn item >
                                    <Typography variant='caption' aria-label='subject'>Subject</Typography>
                                    <Typography variant='body1' >{subject}</Typography>
                                </ReadOnlyGridColumn>
                                <ReadOnlyGridColumn item >
                                    <Typography variant='caption' aria-label='body type'>Body Type</Typography>
                                    <Typography variant='body1' >{bodyType} </Typography>
                                </ReadOnlyGridColumn>
                            </Stack>
                        </Grid>
                    </DialogGridColumn>
                    <DialogGridColumn id="detailsColumn2" container item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                <ReadOnlyGridColumn item >
                                    <Typography variant='caption' aria-label='formatter type'>Custom Formatter Type</Typography>
                                    <Typography variant='body1' >{formatterType}</Typography>        
                                </ReadOnlyGridColumn>
                                <ReadOnlyGridColumn item >
                                    <Typography variant='caption' aria-label='as attachment'>As Attachment</Typography>
                                    <Typography variant='body1' >{isAsAttachment ? 'Yes' : 'No'} </Typography>
                                </ReadOnlyGridColumn>
                                <ReadOnlyGridColumn item >
                                    <Typography variant='caption' aria-label='is for error'>Is For Error</Typography>
                                    <Typography variant='body1' >{isForError ? 'Yes' : 'No'} </Typography>
                                </ReadOnlyGridColumn>
                            </Stack>
                        </Grid>
                    </DialogGridColumn>
                </DialogGridWithTopMargin>
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='body'>Body</Typography>
                    <Typography variant='body1' >{body}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {templateString && 
                        <Button
                            color="primary"
                            startIcon={<DownloadIcon/>}
                            onClick={onDownloadTemplateClick}
                            aria-label={"download template button"}
                        >Download Template</Button>
                    }
                    {(templateString === null || templateString === undefined) && 
                        <Typography variant='caption' aria-label='template'>Template</Typography>
                    }
                </Grid>
                <Grid item xs={12}>
                    {getTransactionsList()}
                </Grid>
            </Grid>
        );
    };

    const descriptionProps = {
        'aria-label': 'description',
        'maxLength': 255,
    };
    const formatterTypeProps = {
        'aria-label': 'formatter type',
        'maxLength': 255,
    };
    const subjectProps = {
        'aria-label': 'subject',
        'maxLength': 255,
    };
    const bodyProps = {
        'aria-label': 'body',
    };
    const attachmentProps = {
        'aria-label': 'as attachment',
    };
    const isForErrorProps = {
        'aria-label': 'is for error',
    };

    const getAddEditFields = () => {
        return (
            <Grid container spacing={2} item xs={12}>
                <DialogGridWithTopMargin container item columns={{ xs: 1, sm: 2 }} spacing={2} xs={12}>
                    <DialogGridColumn id="detailsColumn1" container item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        itemID="dialog-formatter-description"
                                        fullWidth
                                        autoFocus
                                        disabled={submitted}
                                        value={description}
                                        label="Description"
                                        inputProps={descriptionProps}
                                        onChange={onDescriptionChange}
                                        autoComplete="off"
                                        required
                                        data-cy="dialog-formatter-description"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        itemID="dialog-formatter-subject"
                                        fullWidth
                                        disabled={submitted}
                                        value={subject ?? ''}
                                        label="Subject"
                                        inputProps={subjectProps}
                                        onChange={onSubjectChange}
                                        autoComplete="off"
                                        data-cy="dialog-formatter-subject"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel id="dialog-formatter-body-type-label"><Typography variant="caption">Body Type</Typography></FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="dialog-formatter-body-type-label"
                                            name="body-type-radio-buttons-group"
                                            value={bodyType}
                                            onChange={onBodyTypeChange}
                                            data-cy="dialog-formatter-body-type"
                                        >
                                            <FormControlLabel value={NotificationFormatterBodyType.Html} control={<Radio />} label="HTML" disabled={submitted} aria-label="html" />
                                            <FormControlLabel value={NotificationFormatterBodyType.Text} control={<Radio />} label="Text" disabled={submitted} aria-label="text" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                
                            </Stack>
                        </Grid>
                    </DialogGridColumn>
                    <DialogGridColumn id="detailsColumn2" container item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        itemID="dialog-formatter-type"
                                        fullWidth
                                        disabled={submitted}
                                        value={formatterType ?? ''}
                                        label="Custom Formatter Type"
                                        inputProps={formatterTypeProps}
                                        onChange={onFormatterTypeChange}
                                        autoComplete="off"
                                        data-cy="dialog-formatter-type"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        label="As Attachment"
                                        control={
                                            <Switch
                                                itemID="dialog-formatter-as-attachment"
                                                color="primary"
                                                disabled={submitted}
                                                checked={isAsAttachment}
                                                inputProps={attachmentProps}
                                                onChange={onAsAttachmentChange}
                                                data-cy="dialog-formatter-as-attachment"
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        label="Is For Error"
                                        control={
                                            <Switch
                                                itemID="dialog-formatter-is-for-error"
                                                color="primary"
                                                disabled={submitted}
                                                checked={isForError}
                                                inputProps={isForErrorProps}
                                                onChange={onIsForErrorChange}
                                                data-cy="dialog-formatter-is-for-error"
                                            />
                                        }
                                    />
                                </Grid>
                            </Stack>
                        </Grid>
                    </DialogGridColumn>
                </DialogGridWithTopMargin>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-formatter-body"
                        fullWidth
                        multiline
                        minRows={4}
                        maxRows={8}
                        disabled={submitted}
                        value={body ?? ''}
                        label="Body"
                        inputProps={bodyProps}
                        onChange={onBodyChange}
                        autoComplete="off"
                        data-cy="dialog-formatter-body"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FileDropZone
                        label="Template File"
                        maxFileSize={maxFileSize}
                        maxNumberFiles={1}
                        dropInstructionText="Drop your template file here"
                        existingFiles={existingTemplateFile ? [existingTemplateFile] : undefined}
                        acceptedFileTypes={{
                            'text/xml': ['.xslt'],
                            'text/html': ['.html', '.htm'],
                            'text/txt': ['.txt'],
                        }}
                        onChange={onFileUploadChange}
                        data-cy="dialog-formatter-template"
                    />
                </Grid>
                <Grid item xs={12}>
                    { id && (
                        getTransactionsList()
                    )}
                </Grid>
            </Grid>
        );
    };
    
    const getContent = () => {
        return isViewMode ? getViewFields() : getAddEditFields();
    };

    // form
    return (
        <AddEditDialog 
            isOpen={isOpen} 
            isLoading={id && !formatter ? true : false}
            isSubmitted={submitted}
            isReadOnly={isReadOnly}
            viewerRole={viewerRole?.role}
            id={id} 
            entityName="Notification Formatter" 
            maxWidth='md'
            onClose={onClose} 
            onCancel={onEditCancel}
            onSave={submitForm} 
            onViewModeEdit={viewModeEditForm}
            validate={isFormValid}
            onError={onError}
            error={error}
        >
            {getContent()}
        </AddEditDialog>
    );
};

export default NotificationFormatterDialog;