import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertNotificationFormatterInput, UpsertNotificationFormatterPayload, NotificationFormatterBodyType } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_NOTIFICATION_FORMATTER = gql`
    mutation upsertNotificationFormatter($input: UpsertNotificationFormatterInput!){
        upsertNotificationFormatter(input: $input){
            message
            errors {
                code
                message
            }
            result
            notificationFormatter {
                id
            }
        }
    }
`;

export const mutationUpsertNotificationFormatter = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        upsertNotificationFormatterData: UpsertNotificationFormatterInput
    }
): Promise<UpsertNotificationFormatterPayload | undefined> => {
    const { description, formatterType, id, subject, body, bodyType, template, asAttachment, isForError, fileType } = args.upsertNotificationFormatterData;
    const { data, errors } = await client.mutate<{ upsertNotificationFormatter: UpsertNotificationFormatterPayload }>({
        variables: {
            input: {
                description,
                formatterType,
                id,
                subject,
                body,
                bodyType,
                template,
                asAttachment,
                isForError,
                fileType
            }
        },
        mutation: MUTATION_UPSERT_NOTIFICATION_FORMATTER
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the process save.');
    }
    if (data && data.upsertNotificationFormatter) {
        return data.upsertNotificationFormatter;
    }

    return undefined;
};