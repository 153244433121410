import { gqlClient } from '../../components/AppProvider';
import { BusinessAreaModel, EntityType, ErpProductModel, EdiStandardModel, TransactionModel, RequestResult, StateModel, PhoneTypeModel, UserModel, PagingResultModelOfClientModel, PipelineItemClassNameModel, IsaQualifierModel, PricingModel, ErpTransactionSchemaModel, CurrencyModel, GetMessagingLogsInput, PagingResultModelOfMessagingLogModel, DashboardModel, GetDashboardCountsInput, EdiActionServiceInput, LocaleModel } from '../../gql-types.generated';
import { AppDispatch, AppThunk } from '../../store';
import { eventLogErrorsFilterDate, documentArchiveUnacknowledgedFilterDate } from '../../util/Constants';
import { getErrorMessage } from '../../util/Common';
import { captureUpsertRecentStatus, captureUpsertFavoriteStatus, fetchError, fetchUserByEmailSuccess, fetchBusinessAreaListSuccess, fetchErpProductListSuccess, fetchEdiStandardListSuccess, fetchStatesListSuccess, fetchPhoneTypeListSuccess, fetchIsaQualifierListSuccess, fetchClientsOnboardingSuccess, fetchOnboardingClientsError, fetchPipelineItemClassListSuccess, fetchTransactionListSuccess, fetchPricingListSuccess, fetchErpSchemasForTransactionSuccess, fetchCurrencyListSuccess, fetchErrorLogsSuccess, fetchDashboardCountsSuccess, captureGenerateBillingStatus, fetchLocaleListSuccess, capturePortalSyncStatus } from './EDIContainerSlice';
import { queryUserByEmail } from '../../gql/QueryUserByEmail';
import { queryProductList } from '../../gql/QueryProductsList';
import { queryBusinessAreaList } from '../../gql/QueryBusinessAreasList';
import { queryEdiStandardList } from '../../gql/QueryEdiStandardsList';
import { queryTransactionList } from '../../gql/QueryTransactionsList';
import { queryStatesList } from '../../gql/QueryStatesList';
import { queryPhoneTypeList } from '../../gql/QueryPhoneTypesList';
import { queryIsaQualifierList } from '../../gql/QueryIsaQualifiersList';
import { queryClientsOnboarding } from '../../gql/QueryClientsOnboarding';
//import { queryPipelinesByTransactionId } from '../../gql/QueryPipelinesByTransactionId';
import { queryPricingList } from '../../gql/QueryPricingsList';
import { mutationUpsertRecent } from '../../gql/MutationUpsertRecent';
import { mutationUpsertFavorite } from '../../gql/MutationUpsertFavorite';
import { ApolloError } from '@apollo/client/errors';
import { queryErpSchemasByTransactionId } from '../../gql/QueryErpTransactionSchemasByTransactionId';
import { queryCurrencyList } from '../../gql/QueryCurrencyList';
import { queryMessagingLogList } from '../../gql/QueryMessagingLogList';
import { queryDashboardCounts } from '../../gql/QueryDashboardCounts';
import { mutationExecuteEdiActionSvcAction } from '../../gql/MutationExecuteEdiActionSvcAction';
import { queryLocaleList } from '../../gql/QueryLocaleList';
import { queryPipelineItemClassList } from '../../gql/QueryPipelineItemClassList';

export const fetchUserByEmail =
    (email: string) =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const user = await queryUserByEmail(gqlClient, email);
                if (user) {
                    dispatch(fetchUserByEmailSuccess(user as UserModel));
                }
            } catch (e) {
                dispatch(fetchError(e as ApolloError));
            }
        };

export const fetchBusinessAreaList = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const areaList = await queryBusinessAreaList(gqlClient);
            if (areaList) {
                dispatch(fetchBusinessAreaListSuccess(areaList as BusinessAreaModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as ApolloError));
        }
    };

export const fetchErpProductList = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const productList = await queryProductList(gqlClient);
            if (productList) {
                dispatch(fetchErpProductListSuccess(productList as ErpProductModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as ApolloError));
        }
    };


export const fetchEdiStandardList = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const standardList = await queryEdiStandardList(gqlClient);
            if (standardList) {
                dispatch(fetchEdiStandardListSuccess(standardList as EdiStandardModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as ApolloError));
        }
    };

export const fetchTransactionList = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const transactionList = await queryTransactionList(gqlClient, {});
            if (transactionList) {
                dispatch(fetchTransactionListSuccess(transactionList as TransactionModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as ApolloError));
        }
    };

export const fetchStatesList = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const statesList = await queryStatesList(gqlClient);
            if (statesList) {
                dispatch(fetchStatesListSuccess(statesList as StateModel[]));
            }
        } catch (e) {
            // non-serialized content should be handled
            const serializedError = JSON.parse(JSON.stringify(e));
            dispatch(fetchError(serializedError as ApolloError));
        }
    };

export const fetchPhoneTypeList = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const phoneList = await queryPhoneTypeList(gqlClient);
            if (phoneList) {
                dispatch(fetchPhoneTypeListSuccess(phoneList as PhoneTypeModel[]));
            }
        } catch (e) {
            // non-serialized content should be handled
            const serializedError = JSON.parse(JSON.stringify(e));
            dispatch(fetchError(serializedError as ApolloError));
        }
    };

export const fetchIsaQualifierList = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const qualifierList = await queryIsaQualifierList(gqlClient);
            if (qualifierList) {
                dispatch(fetchIsaQualifierListSuccess(qualifierList as IsaQualifierModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as ApolloError));
        }
    };

export const fetchLocaleList = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const localeList = await queryLocaleList(gqlClient);
            if (localeList) {
                dispatch(fetchLocaleListSuccess(localeList as LocaleModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as ApolloError));
        }
    };

export const fetchPricingList = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const pricingList = await queryPricingList(gqlClient, {});
            if (pricingList) {
                dispatch(fetchPricingListSuccess(pricingList as PricingModel[]));
            }
        } catch (e) {
            // non-serialized content should be handled
            const serializedError = JSON.parse(JSON.stringify(e));
            dispatch(fetchError(serializedError as ApolloError));
        }
    };

export const fetchOnboardingClients = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const clients = await queryClientsOnboarding(gqlClient);
            if (clients) {
                dispatch(fetchClientsOnboardingSuccess(clients as PagingResultModelOfClientModel));
            }
        } catch (e) {
            // non-serialized content should be handled
            const serializedError = JSON.parse(JSON.stringify(e));
            dispatch(fetchOnboardingClientsError(serializedError as ApolloError));
        }
    };

export const upsertRecentData =
    (entityType: EntityType, entityId: string, name: string): AppThunk =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const upsertRecentStatus = await mutationUpsertRecent(gqlClient, {
                    entityType,
                    entityId,
                    name,
                });
                if (upsertRecentStatus) {
                    if (upsertRecentStatus.result === RequestResult.Fail) {
                        dispatch(fetchError({ message: upsertRecentStatus.message } as ApolloError));
                        getErrorMessage(upsertRecentStatus.message as string, upsertRecentStatus.errors, true, true);
                    }
                    dispatch(captureUpsertRecentStatus(upsertRecentStatus));
                }
            } catch (e) {
                dispatch(fetchError(e as ApolloError));
            }
        };

export const upsertFavoriteData =
    (entityType: EntityType, entityId: string, isFavorite: boolean, name: string, id?: string): AppThunk =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const upsertFavoriteStatus = await mutationUpsertFavorite(gqlClient, {
                    entityType,
                    entityId,
                    isFavorite,
                    name,
                    id
                });
                if (upsertFavoriteStatus) {
                    if (upsertFavoriteStatus.result === RequestResult.Fail) {
                        dispatch(fetchError({ message: upsertFavoriteStatus.message } as ApolloError));
                        getErrorMessage(upsertFavoriteStatus.message as string, upsertFavoriteStatus.errors, true, true);
                    }
                    dispatch(captureUpsertFavoriteStatus(upsertFavoriteStatus));
                }
            } catch (e) {
                dispatch(fetchError(e as ApolloError));
            }
        };

// export const fetchPipelinesByTransactionId = (transactionId: string): AppThunk =>
//     async (dispatch: AppDispatch): Promise<void> => {
//         try {
//             const pipelines = await queryPipelinesByTransactionId(gqlClient, transactionId);
//             if (pipelines) {
//                 dispatch(fetchPipelinesForTransactionSuccess(pipelines as PipelineModel[]));
//             }
//         } catch (e) {
//             // non-serialized content should be handled
//             //const serializedError = JSON.parse(JSON.stringify(e));
//             //dispatch(fetchOnboardingClientsError(serializedError as ApolloError));
//         }
//     };

export const fetchPipelineItemClassList = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const classList = await queryPipelineItemClassList(gqlClient);
            if (classList) {
                dispatch(fetchPipelineItemClassListSuccess(classList as PipelineItemClassNameModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as ApolloError));
        }
    };

export const fetchErpSchemasByTransactionId = (transactionId: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const schemas = await queryErpSchemasByTransactionId(gqlClient, transactionId);
            if (schemas) {
                dispatch(fetchErpSchemasForTransactionSuccess(schemas as ErpTransactionSchemaModel[]));
            }
        } catch (e) {
            // non-serialized content should be handled
            //const serializedError = JSON.parse(JSON.stringify(e));
            //dispatch(fetchOnboardingClientsError(serializedError as ApolloError));
        }
    };

export const fetchCurrencyList = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const currencyList = await queryCurrencyList(gqlClient);
            if (currencyList) {
                dispatch(fetchCurrencyListSuccess(currencyList as CurrencyModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as ApolloError));
        }
    };

export const fetchErrorLogList = () => async (dispatch: AppDispatch): Promise<void> => {
    const filters = {
        status: "ERROR",
        order:[{createdTime: "DESC"}]
    } as GetMessagingLogsInput;
    try {
        const eventLogList = await queryMessagingLogList(gqlClient, filters);
        if (eventLogList) {
            dispatch(fetchErrorLogsSuccess(eventLogList as PagingResultModelOfMessagingLogModel))
        }
    } catch (e) {
        dispatch(fetchError(e as ApolloError));
    }
};

export const fetchDashboardCounts = () => async (dispatch: AppDispatch): Promise<void> => {
    // set date to the constant that defines how many days' worth of errors to bring back, which is currently 7 days ago
    const dateFrom = eventLogErrorsFilterDate; 
    // set date to the constant that defines how many hours ago to bring back unacknowledged transactions from, and is currently 4 hours ago
    const dateTo = documentArchiveUnacknowledgedFilterDate;
    const filters = {
        errorDateFrom: dateFrom,
        unacknowledgedDateTo: dateTo
    } as GetDashboardCountsInput;
    try {
        const counts = await queryDashboardCounts(gqlClient, filters);
        if (counts) {
            dispatch(fetchDashboardCountsSuccess(counts as DashboardModel))
        }
    } catch (e) {
        dispatch(fetchError(e as ApolloError));
    }
};

export const generateBillingReport = (billingReportArguments: string) : AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const actionServiceStatus = await mutationExecuteEdiActionSvcAction(gqlClient,{
                actionName: "BillingReportAction",
                actionArguments: billingReportArguments
            });
            if (actionServiceStatus) {
                if (actionServiceStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({message: actionServiceStatus.message} as ApolloError));
                    getErrorMessage(actionServiceStatus.message as string, actionServiceStatus.errors, true, true);
                }
                dispatch(captureGenerateBillingStatus(actionServiceStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as ApolloError));
        }
    };

export const synchronizeWithPortal = () : AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const actionServiceStatus = await mutationExecuteEdiActionSvcAction(gqlClient,{
                actionName: "SyncPortalToEdiAction"
            });
            if (actionServiceStatus) {
                if (actionServiceStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({message: actionServiceStatus.message} as ApolloError));
                    getErrorMessage(actionServiceStatus.message as string, actionServiceStatus.errors, true, true);
                }
                dispatch(capturePortalSyncStatus(actionServiceStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as ApolloError));
        }
    };