import { gqlClient } from '../../components/AppProvider';
import { AppDispatch } from '../../store';
import { DateTime } from 'luxon';
import { PagingResultModelOfMessagingLogModel, GetMessagingLogsInput } from '../../gql-types.generated';
import { queryMessagingLogList } from "../../gql/QueryMessagingLogList";
import { decrementEventLogRequestsInFlight, fetchError, fetchEventLogListSuccess, incrementEventLogRequestsInFlight } from './EventLogsSlice';

export const fetchEventLogList = (
    filters?: GetMessagingLogsInput
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementEventLogRequestsInFlight());
    try {
        const eventLogList = await queryMessagingLogList(gqlClient, filters);
        if (eventLogList) {
            dispatch(fetchEventLogListSuccess(eventLogList as PagingResultModelOfMessagingLogModel))
        }
        dispatch(decrementEventLogRequestsInFlight());
    } catch (e) {
        dispatch(fetchError(e as Error));
        dispatch(decrementEventLogRequestsInFlight());
    }
};

