import { Box, CircularProgress, Container, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { styled } from '@mui/system';
import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { DateTime } from 'luxon';

import PrimaryAppBar from '../../components/PrimaryAppBar';
import Users from '../Users/Users';
import Home from '../Home/Home';
import Clients from '../Clients/Clients';
import ClientDetails from '../ClientDetails/ClientDetails';
import ClientPartnerDetails from '../ClientPartnerDetails/ClientPartnerDetails';
import TradingPartners from '../TradingPartners/TradingPartners';
import TradingPartnerDetails from '../TradingPartnerDetails/TradingPartnerDetails';
import EventLogs from '../EventLogs/EventLogs';
import BusinessAreas from '../BusinessAreas/BusinessAreas';
import EDIStandards from '../EDIStandards/EDIStandards';
import Products from '../Products/Products';
import Transactions from '../Transactions/Transactions';
import Pipelines from '../Pipelines/Pipelines';
import PipelineDetails from '../PipelineDetails/PipelineDetails';
import Maps from '../Maps/Maps';
import MapDetails from '../MapDetails/MapDetails';
import Billing from '../Billing/Billing';
import BillingDetails from '../BillingDetails/BillingDetails';
import Pricings from '../Pricing/Pricings';
import PricingDetails from '../PricingDetails/PricingDetails';
import Currencies from '../Currencies/Currencies';
import UnauthorizedView from '../../components/Unauthorized';
import ERPTransactionSchemas from '../ErpTransactionSchemas/ErpTransactionSchemas';
import SystemConfiguration from '../SystemConfiguration/SystemConfiguration';
import NotificationFormatters from '../NotificationFormatters/NotificationFormatters';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { fetchUserByEmail, fetchBusinessAreaList, fetchErpProductList, fetchEdiStandardList, fetchTransactionList, fetchStatesList, fetchPhoneTypeList, fetchIsaQualifierList, fetchPricingList, fetchCurrencyList, generateBillingReport, fetchLocaleList, fetchPipelineItemClassList } from './EDIContainerActions';
import { selectError, selectUpsertFavoriteStatus, selectUpsertRecentStatus, selectUser, selectBusinessAreaList, selectEdiStandardList, selectErpProductList, selectTransactionList, selectStatesList, selectPhoneTypeList, selectIsaQualifierList, selectPricingList, setToastConfig, selectToastConfig, selectCurrencyList, selectGenerateBillingStatus, captureGenerateBillingStatus, selectLocaleList, selectPipelineItemClassList } from './EDIContainerSlice';
import { FavoriteModel, RecentModel, RequestResult, UserRole } from '../../gql-types.generated';
import { GeneralMessagePaper, Toast } from '../../util/SharedStyles';
import SnackbarPrompt, { SnackbarPromptOptions } from '../../components/SnackbarPrompt';
import Processes from '../Processes/Processes';
import { GraphQLError } from 'graphql';
import MessageLogDetails from '../MessageLogDetails/MessageLogDetails';
import DocumentArchives from '../DocumentArchives/DocumentArchives';
import BillingReportDialog from '../../components/dialogs/BillingReportDialog';
import CrossReferenceTable from '../CrossReferenceTable/CrossReferenceTable';
import PostProcessingBlobs from '../PostProcessingBlobs/PostProcessingBlobs';
import PortalSyncDialog from '../../components/dialogs/PortalSyncDialog';
import EDIStaticFiles from '../EDIStaticFiles/EDIStaticFiles';


const EDIContainerRoot = styled(Box)`
    flex-grow: 2;
    min-height: 100%;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding-left: 0px;
    padding-right: 0px;
    padding: 0;
`;

// wrapper Container to keep the scroll inside of the PrimaryAppBar
// with small margins to keep content from blending into appBar as you scroll
const EDIContainerScrollWrapper = styled(Container)`
    max-width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: 0px;
    overflow: hidden;
    display: flex;
    justify-content: center;
`;
const Loader = styled('div')((props) => ({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
}));

interface EDIContainerProps {
    authError: GraphQLError | undefined;
}

const EDIContainer: React.FC<EDIContainerProps> = (props) => {
    const { authError } = props;
    const dispatch = useAppDispatch();
    const {
        isLoading,
        isAuthenticated,
        error,
        loginWithRedirect,
        //getAccessTokenSilently,
        logout,
    } = useAuth0();

    const { user } = useAuth0<{ name: string, email: string, email_verified: boolean, nickname: string, picture: string }>();
    const [viewer, setViewer] = useState<Viewer | undefined>(undefined);
    const [searchText, setSearchText] = useState(''); // Dirty state of the form.
    const userStatus = useAppSelector(selectUser);
    const userError = useAppSelector(selectError);
    const upsertRecentStatus = useAppSelector(selectUpsertRecentStatus);
    const upsertFavoritesStatus = useAppSelector(selectUpsertFavoriteStatus);
    const businessAreas = useAppSelector(selectBusinessAreaList);
    const erpProducts = useAppSelector(selectErpProductList);
    const ediStandards = useAppSelector(selectEdiStandardList);
    const transactions = useAppSelector(selectTransactionList);
    const states = useAppSelector(selectStatesList);
    const phoneTypes = useAppSelector(selectPhoneTypeList);
    const isaQualifiers = useAppSelector(selectIsaQualifierList);
    const locales = useAppSelector(selectLocaleList);
    const pricings = useAppSelector(selectPricingList);
    const currencies = useAppSelector(selectCurrencyList);
    const pipelineItemClasses = useAppSelector(selectPipelineItemClassList);
    const toastConfig = useAppSelector(selectToastConfig);
    const generateBillingStatus = useAppSelector(selectGenerateBillingStatus);
    const [heightUnderAppBar, setHeightUnderAppBar] = useState('');

    const [snackbarPromptOpen, setSnackbarPromptOpen] = useState(false);
    const [snackbarPromptOptions, setSnackbarPromptOptions] = useState({} as SnackbarPromptOptions);

    const [isBillingReportOpen, setIsBillingReportOpen] = useState(false);
    const [isBillingReportLoading, setIsBillingReportLoading] = useState(false);
    const [billingReportPreview, setBillingReportPreview] = useState<string | undefined>(undefined);

    const [isPortalSyncOpen, setIsPortalSyncOpen] = useState(false);

    let SuccessSnackbarPromptOptions: SnackbarPromptOptions = {
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        message: 'User was Re-activated.',
        autoHideDuration: 2500,
        transitionDuration: 500,
        severity: ToastSeverity.Success
    };

    const performSearch = () => {
        alert('This is a placeholder for running a search');
    }

    const searchTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }

    const handleLogoutClick = () => {
        // TODO: handle logout gracefully, redirect to home page / login page
        logout({ returnTo: window.location.origin })
    }

    useEffect(() => {
        const email = user?.email;
        if (email) {
            dispatch(fetchUserByEmail(email));
        }
        const appBarHeight = document.getElementById('primary-app-bar')?.clientHeight;
        setHeightUnderAppBar(`calc(100vh - ${appBarHeight}px)`);
    }, [dispatch, user]);

    useEffect(() => {
        // fetch userbyEmail to refresh viewer when a successful mutation occurs
        if (upsertFavoritesStatus?.result === RequestResult.Success || upsertRecentStatus?.result === RequestResult.Success) {
            const email = user?.email;
            if (email) {
                dispatch(fetchUserByEmail(email));
            }
        }
    }, [upsertFavoritesStatus, upsertRecentStatus])

    useEffect(() => {
        let combinedViewer = {} as Viewer;
        if (user) {
            if (user.hasOwnProperty('name')) {
                combinedViewer.name = user.name;
            }
            if (user.hasOwnProperty('email_verified')) {
                combinedViewer.email_verified = user.email_verified;
            }
            if (user.hasOwnProperty('nickname')) {
                combinedViewer.nickname = user.nickname;
            }
            if (user.hasOwnProperty('picture')) {
                combinedViewer.picture = user.picture;
            }
        }
        if (userStatus) {
            if (userStatus.hasOwnProperty('email')) {
                combinedViewer.email = userStatus.email;
            }
            if (userStatus.hasOwnProperty('firstName')) {
                combinedViewer.firstName = userStatus.firstName;
            }
            if (userStatus.hasOwnProperty('id')) {
                combinedViewer.id = userStatus.id as string;
            }
            if (userStatus.hasOwnProperty('lastName')) {
                combinedViewer.lastName = userStatus.lastName;
            }
            if (userStatus.hasOwnProperty('role')) {
                combinedViewer.role = userStatus.role as UserRole;
            }
            if (userStatus.hasOwnProperty('recents')) {
                combinedViewer.recents = userStatus.recents as RecentModel[];
            }
            if (userStatus.hasOwnProperty('favorites')) {
                combinedViewer.favorites = userStatus.favorites as FavoriteModel[];
            }
        }
        setViewer(combinedViewer);
    }, [user, userStatus]);

    useEffect(() => {
        if (viewer && viewer.role) {
            if (!businessAreas) {
                dispatch(fetchBusinessAreaList());
            }
            if (!erpProducts) {
                dispatch(fetchErpProductList());
            }
            if (!ediStandards) {
                dispatch(fetchEdiStandardList());
            }
            if (!transactions) {
                dispatch(fetchTransactionList());
            }
            if (!states) {
                dispatch(fetchStatesList());
            }
            if (!phoneTypes) {
                dispatch(fetchPhoneTypeList());
            }
            if (!isaQualifiers) {
                dispatch(fetchIsaQualifierList());
            }
            if (!locales) {
                dispatch(fetchLocaleList());
            }
            if (!pricings) {
                dispatch(fetchPricingList());
            }
            if (!currencies) {
                dispatch(fetchCurrencyList());
            }
            if (!pipelineItemClasses) {
                dispatch(fetchPipelineItemClassList());
            }
        }
    }, [viewer]);

    useEffect(() => {
        if (toastConfig && toastConfig.message) {
            let severity = ToastSeverity.Success;
            if (toastConfig.severity) {
                severity = toastConfig.severity;
            }
            setSnackbarPromptOptions({
                anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
                message: toastConfig.message,
                autoHideDuration: 2500,
                transitionDuration: 500,
                severity: severity
            });
            setSnackbarPromptOpen(true);
        }
    }, [toastConfig]);

    useEffect(() => {
        if (generateBillingStatus?.result  === RequestResult.Success) {
            if (generateBillingStatus?.data) {
                // we have to do somethign with this returned data!
                // store the base64 until the user decides what they want to do with it
                setBillingReportPreview(generateBillingStatus?.data);
            } else {
                // nothing additional to do let the user know it was successful
                dispatch(setToastConfig({
                    message: generateBillingStatus.message as string,
                    severity: ToastSeverity.Success
                }));
                handleCloseBillingReport();
            }
            dispatch(captureGenerateBillingStatus());
        } else if (generateBillingStatus?.result  === RequestResult.Fail){
            dispatch(setToastConfig({
                message: generateBillingStatus.message as string,
                severity: ToastSeverity.Error
            }));
            handleCloseBillingReport();
            dispatch(captureGenerateBillingStatus());
        }

    }, [generateBillingStatus?.result]);

    const getAppContent = () => {
        if (isAuthenticated && !authError) {
            if (viewer?.role) {
                const isViewerAdmin = viewer?.role === UserRole.Admin;
                const isViewerEditor = viewer?.role === UserRole.Editor;
                const isSetupViewer = isViewerAdmin || isViewerEditor;

                return (
                    <Routes>
                        <Route path="/home" element={<Home viewer={viewer} />} />
                        {isViewerAdmin &&
                            <Route path="/users" element={<Users searchText={searchText} viewer={viewer} />} />
                        }
                        {isViewerAdmin &&
                            <Route path="/billing" element={<Billing viewer={viewer} />} />
                        }
                        {isViewerAdmin &&
                            <Route path="/billingdetails/:billingId" element={<BillingDetails viewer={viewer} />} />
                        }
                        {isViewerAdmin &&
                            <Route path="/pricing" element={<Pricings viewer={viewer} />} />
                        }
                        {isViewerAdmin &&
                            <Route path="/pricingdetails/:pricingId" element={<PricingDetails viewer={viewer} />} />
                        }
                        {isViewerAdmin &&
                            <Route path="/postprocessingassemblies" element={<PostProcessingBlobs viewer={viewer} />} />
                        }
                        {isSetupViewer &&
                            <Route path="/businessareas" element={<BusinessAreas viewer={viewer} />} />
                        }
                        {isSetupViewer &&
                            <Route path="/edistandards" element={<EDIStandards viewer={viewer} />} />
                        }
                        {isSetupViewer &&
                            <Route path="/products" element={<Products viewer={viewer} />} />
                        }
                        {isSetupViewer &&
                            <Route path="/transactions" element={<Transactions viewer={viewer} />} />
                        }
                        {isSetupViewer &&
                            <Route path="/pipelines" element={<Pipelines viewer={viewer} />} />
                        }
                        {isSetupViewer &&
                            <Route path="/pipeline/:pipelineId" element={<PipelineDetails viewer={viewer} />} />
                        }
                        {isSetupViewer &&
                            <Route path="/maps" element={<Maps viewer={viewer} />} />
                        }
                        {isSetupViewer &&
                            <Route path="/transactionmap/:mapId" element={<MapDetails viewer={viewer} />} />
                        }
                        {isSetupViewer &&
                            <Route path="/processes" element={<Processes viewer={viewer} />} />
                        }
                        {isSetupViewer &&
                            <Route path="/erptransactionschemas" element={<ERPTransactionSchemas viewer={viewer} />} />
                        }
                        {isSetupViewer &&
                            <Route path="/currencies" element={<Currencies viewer={viewer} />} />
                        }
                        {isSetupViewer && 
                            <Route path="/config" element={<SystemConfiguration viewer={viewer} />} />
                        }
                        {isSetupViewer && 
                            <Route path="/notificationformatters" element={<NotificationFormatters viewer={viewer} />} />
                        }
                        {isSetupViewer &&
                             <Route path="/crossReferenceTable" element={<CrossReferenceTable viewer={viewer} />} />
                        }
                        {isSetupViewer &&
                            <Route path="/staticresources" element={<EDIStaticFiles viewer={viewer} />} />
                        }
                        <Route path="/clients" element={<Clients viewer={viewer} />} />
                        <Route path="/client/:clientId" element={<ClientDetails viewer={viewer} />} />
                        <Route path="/clientpartner/:clientPartnerId" element={<ClientPartnerDetails viewer={viewer} />} />
                        <Route path="/tradingpartners" element={<TradingPartners viewer={viewer} />} />
                        <Route path="/partner/:partnerId" element={<TradingPartnerDetails viewer={viewer} />} />
                        <Route path="/eventlogs/:premadeQuery" element={<EventLogs viewer={viewer} />} />
                        <Route path="/eventlogs">
                            <Route index element={<EventLogs viewer={viewer} />} />
                            <Route path=":premadeQuery" />
                        </Route>
                        <Route path="/messaginglog/:messageId" element={<MessageLogDetails viewer={viewer} />} />
                        <Route path="/archives/:premadeQuery" element={<DocumentArchives viewer={viewer} />} />
                        <Route path="/archives">
                            <Route index element={<DocumentArchives viewer={viewer} />} />
                            <Route path=":premadeQuery" />
                        </Route>
                        <Route path="/" element={<Home viewer={viewer} />} />
                        <Route path="*" element={<UnauthorizedView />} />
                    </Routes>
                );
            } else if (userError) {
                return <Toast severity='error'>{userError.message}</Toast>;
            } else {
                return (
                    <Loader id='loader' >
                        <CircularProgress />
                    </Loader>
                );
            }
        } else if (isLoading) {
            return (
                <Loader id='loader' >
                    <CircularProgress />
                </Loader>
            );
        } else if (authError) {
            loginWithRedirect({ appState: { returnTo: window.location.href } });
            return <GeneralMessagePaper>Redirecting to your login page</GeneralMessagePaper>;
        } else if (error) {
            return <Toast severity='error'>{error.message}</Toast>;
        } else {
            loginWithRedirect({ appState: { returnTo: window.location.href } });
            return <GeneralMessagePaper>Redirecting to your login page</GeneralMessagePaper>;
        }
    };

    const handleSnackBarClose = () => {
        setSnackbarPromptOpen(false);
        dispatch(setToastConfig());
    };

    const handleOpenBillingReport = () => {
        setIsBillingReportOpen(true);
    };

    const handleOpenPortalSyncDialog = () => {
        setIsPortalSyncOpen(true);
    };

    const handleClosePortalSyncDialog = () => {
        setIsPortalSyncOpen(false);
    };

    const handleCloseBillingReport = () => {
        setIsBillingReportOpen(false);
        setBillingReportPreview(undefined);
        setIsBillingReportLoading(false);
        // clear preview
        setBillingReportPreview(undefined);
    };

    const saveBillingReport = (
        date: DateTime,
        aggregates: boolean,
        sendEmail: boolean,
        download: boolean
    ) => {
        let year = date.year;
        let month = date.month;

        let argumentString =`{Month: ${month.toString()}, Year: ${year.toString()}, GenerateAggregates: ${aggregates.toString()}, ShouldSendEmail: ${sendEmail.toString()}, ShouldPreviewFile: ${download.toString()}}`;
        dispatch(generateBillingReport(argumentString));
    };

    return (
        <EDIContainerRoot>
            <PrimaryAppBar 
                handleSearch={performSearch} 
                handleSearchChange={searchTextChanged} 
                handleLogoutClick={handleLogoutClick} 
                handleBillingReport={handleOpenBillingReport} 
                handlePortalSync={handleOpenPortalSyncDialog}
                userContext={viewer} 
            />
            <SnackbarPrompt
                open={snackbarPromptOpen}
                snackbarPromptOptions={snackbarPromptOptions}
                handleClose={handleSnackBarClose}
            />
            <EDIContainerScrollWrapper sx={{ height: heightUnderAppBar }}>
                {getAppContent()}
            </EDIContainerScrollWrapper>
            <BillingReportDialog
                isOpen={isBillingReportOpen}
                isLoading={isBillingReportLoading}
                onClose={handleCloseBillingReport}
                onSave={saveBillingReport}
                preview={billingReportPreview}
            />
            <PortalSyncDialog
                isOpen={isPortalSyncOpen}
                onClose={handleClosePortalSyncDialog}
            />
        </EDIContainerRoot>
    );
};

export default EDIContainer;