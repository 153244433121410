import { DateTime } from "luxon";
import { AddressModel, EmailModel, FavoriteModel, PhoneModel, RecentModel, UserRole } from "../gql-types.generated";

/* eslint-disable @typescript-eslint/naming-convention */
export const API_ENDPOINT = `${process.env.REACT_APP_EDI_API_ENDPOINT}`;

export const AUTH_DOMAIN = `${process.env.REACT_APP_AUTH_DOMAIN}`;
export const AUTH_CLIENTID = `${process.env.REACT_APP_AUTH_CLIENT_KEY}`;
export const AUTH_CLIENT_SECRET = `${process.env.REACT_APP_AUTH_CLIENT_SECRET}`;

export const TRANSACTIONS_UNACKNOWLEDGED_CRITERIA_NUM_HOURS_AGO = `${process.env.REACT_APP_TRANSACTIONS_UNACKNOWLEDGED_CRITERIA_NUM_HOURS_AGO}`;

export const MUI_LICENSE_KEY = `${process.env.REACT_APP_MUI_LICENSE_KEY}`;

export interface Viewer {
    email?: string;
    email_verified?: boolean;
    firstName?: string;
    id?: string;
    lastName?: string;
    name?: string;
    nickname?: string;
    picture?: string;
    role?: UserRole;
    recents: RecentModel[];
    favorites: FavoriteModel[];
}

export interface ModelInputsSubmission {
    address: AddressModel | undefined,
    email: EmailModel | undefined,
    phone: PhoneModel | undefined,
}

export const maxFileSize = 5242880; // 5 MB
export const maxAttachingFiles = 100;
export const maxMapUploadFiles = 1;

export enum TransactionMapStatus {
    Mapped = "Mapped",
    Deployed = "Deployed",
    NotMapped = "Not Mapped"
};

export const emptyGuid = "00000000-0000-0000-0000-000000000000";

export const defaultPageSize = 48;

export enum ToastSeverity {
    Error = "error",
    Warning = "warning",
    Info = "info",
    Success = "success"
};

export type ToastConfig = {
    message: string;
    severity?: ToastSeverity;
};

// we want the last 7 days of errors, so set date to 7 days ago
export const eventLogErrorsFilterDate = DateTime.now().minus({ days: 7});

// we want the transactions in unacknowledged status as of a specified number of hours ago, 
// so set date to configurable hours value, or if none, default to 4 hours ago
export const documentArchiveUnacknowledgedFilterDate = DateTime.now().minus({ hours: Number(TRANSACTIONS_UNACKNOWLEDGED_CRITERIA_NUM_HOURS_AGO) || 4});
