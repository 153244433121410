import { useEffect, useMemo, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useParams } from 'react-router-dom';
import { MainContentBox, DataGridListScrollBox, PageTitleToolbarGrid, MainDataGrid } from '../../util/SharedStyles';
import { GridRenderCellParams, GridColumns, GridRowModel, GridOverlay, GridSortModel, GridToolbarContainer, useGridApiContext, GridSortItem, GridSelectionModel, GridRowParams } from '@mui/x-data-grid-pro';
import { CircularProgress, Button, Dialog, Link, styled } from '@mui/material';
import { debounce } from 'lodash';

import { useTitle, capitalizeWithUnderscoreRemoval, PremadeDocumentArchiveFilterSet } from '../../util/Common';
import { Viewer, defaultPageSize, ToastSeverity, documentArchiveUnacknowledgedFilterDate } from '../../util/Constants';
import { getShortDateString, getTimeString } from '../../util/DateTimeUtility';
import { viewerCanEdit } from '../../util/ViewerUtility';

import PageTitleBar from '../../components/PageTitleBar';
import ErrorMessage from '../../components/ErrorMessage';
import NoResultsMessage from '../../components/NoResultsMessage';
import { 
    clearState, 
    clearError,
    selectError, 
    selectRequestsInFlight, 
    selectDocumentArchiveList, 
    selectDocumentArchivePagingResult,
    selectAcknowledgeDocumentArchivesStatus,
    selectResendEmailsStatus,
    selectDeleteDocumentArchivesStatus,
    captureDeleteDocumentArchivesStatus,
    selectAbandonDocumentArchivesStatus,
    captureAbandonDocumentArchivesStatus,
    captureAcknowledgeDocumentArchivesStatus,
    captureResendEmailsStatus
} from './DocumentArchivesSlice';
import { fetchDocumentArchiveList, deleteDocumentArchives, abandonDocumentArchives, acknowledgeDocumentArchives, resendEmails, fetchDocumentArchiveById } from './DocumentArchivesActions';
import MainDataGridLoadingSkeleton from '../../components/MainDataGridLoadingSkeleton';
import DocumentArchivesFilterBar from '../../components/filters/DocumentArchivesFilterBar';
import FiltersButton from '../../components/buttons/FiltersButton';
import GridCellDualVert from '../../components/listItems/GridCellDualVert';
import { GetDocumentArchivesInput, DocumentArchiveOrderByInput, OrderDirection, DocumentAcknowledgementStatus, RequestResult } from '../../gql-types.generated';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import ConfirmationPrompt from '../../components/ConfirmationPrompt';
import DocumentArchiveDetailsDialog from '../../components/dialogs/DocumentArchiveDetailsDialog';
import theme from '../../Theme';


const ToolbarButton = styled(Button)`
     margin-right: 4px;
`;

interface DocumentArchivesProps {
    viewer: Viewer | undefined;
}

const DocumentArchives: React.FC<DocumentArchivesProps> = (props) => {
    const { premadeQuery } = useParams();
    const { viewer } = props;
    const dispatch = useAppDispatch();  
    const navigate = useNavigate();  
    const [isLoading, setIsLoading] = useState(false);
    const [contentAreaHeight, setContentAreaHeight] = useState('200px'); //need an actual default height so grid doesn't error on load
    //const [openDelete, setOpenDelete] = useState(false);
    const [openAbandon, setOpenAbandon] = useState(false);
    //const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [abandonErrorMessage, setAbandonErrorMessage] = useState('');
    const [openAcknowledgeConfirm, setOpenAcknowledgeConfirm] = useState(false);
    const [acknowledgeErrorMessage, setAcknowledgeErrorMessage] = useState('');
    const [isSettingAcknowledged, setIsSettingAcknowledged] = useState(false);
    const [openResendConfirm, setOpenResendConfirm] = useState(false);
    const [resendEmailsErrorMessage, setResendEmailsErrorMessage] = useState('');
    const [isResendingEmails, setIsResendingEmails] = useState(false);
    const [filtersOpen, setFiltersOpen] = useState(true);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [filterClientName, setFilterClientName] = useState<string | undefined>(undefined);
    const [filterPartnerName, setFilterPartnerName] = useState<string | undefined>(undefined);
    const [filterFileName, setFilterFileName] = useState<string | undefined>(undefined);
    const [filterControlNumber, setFilterControlNumber] = useState<string | undefined>(undefined);
    const [filterFunctionalGroupControlNumber, setFilterFunctionalGroupControlNumber] = useState<string | undefined>(undefined);
    const [filterTransactionControlNumber, setFilterTransactionControlNumber] = useState<string | undefined>(undefined);
    const [filterPurchaseOrder, setFilterPurchaseOrder] = useState<string | undefined>(undefined);
    const [filterErpOrderNumber, setFilterErpOrderNumber] = useState<string | undefined>(undefined);
    const [filterStatuses, setFilterStatuses] = useState<DocumentAcknowledgementStatus[] | undefined>(undefined);
    const [filterDateFrom, setFilterDateFrom] = useState<DateTime | undefined>(undefined);
    const [filterDateTo, setFilterDateTo] = useState<DateTime | undefined>(undefined);
    const [filterDirection, setFilterDirection] = useState<string | undefined>(undefined);
    const [filterDocumentType, setFilterDocumentType] = useState<string | undefined>(undefined);
    const [filterWasNotificationSent, setFilterWasNotificationSent] = useState<boolean | undefined>(undefined);
    const [filterIsNotificationRequired, setFilterIsNotificationRequired] = useState<boolean | undefined>(undefined);
    const [filterCount, setFilterCount] = useState(0);
    const [premadeFilter, setPremadeFilter] = useState<PremadeDocumentArchiveFilterSet | undefined>(undefined);
    const [documentArchiveRows, setDocumentArchiveRows] = useState<GridRowModel[] | undefined>(undefined);
    const [debounceOn, setDebounceOn] = useState(false);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
    const [sortFirstLoad, setSortFirstLoad] = useState(true);
    const [waitForSort, setWaitForSort] = useState(false);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'createdDate',
            sort: 'desc',
        },
    ]);
    const [serverSortModel, setServerSortModel] = useState<DocumentArchiveOrderByInput[] | undefined>([{
        createdTime: OrderDirection.Desc
    } as DocumentArchiveOrderByInput]);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [detailsRowId, setDetailsRowId] = useState<string | undefined>(undefined);

    const pageSize = defaultPageSize;

    const documentArchives = useAppSelector(selectDocumentArchiveList);
    const error = useAppSelector(selectError);
    const requestsInFlight = useAppSelector(selectRequestsInFlight);
    const documentArchivesPagingResult = useAppSelector(selectDocumentArchivePagingResult);
    //const deleteDocumentArchivesStatus = useAppSelector(selectDeleteDocumentArchivesStatus);
    const abandonDocumentArchivesStatus = useAppSelector(selectAbandonDocumentArchivesStatus);
    const acknowledgeDocumentArchivesStatus = useAppSelector(selectAcknowledgeDocumentArchivesStatus);
    const resendEmailsStatus = useAppSelector(selectResendEmailsStatus);
    const canEdit = viewerCanEdit(viewer);

    

    useTitle("Document Archives");

    const debounceOnSortingChanged = useRef(
        debounce((servSortModel) => {
            onServerSortModelChanged(servSortModel);
        }, 1000)
    ).current;

    useEffect(() => {
        dispatch(clearState());
        setTimeout(() => {
            setDebounceOn(true);
        }, 200);
        if (premadeQuery) {
            applyPreset();
        }
    }, [premadeQuery]);

    useEffect(() => {
        if (acknowledgeDocumentArchivesStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: acknowledgeDocumentArchivesStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove acknowledge status
            dispatch(captureAcknowledgeDocumentArchivesStatus());
            // close the confirmation dialog and refresh
            onAcknowledgeDialogClose();
            refreshList();
        } else if (acknowledgeDocumentArchivesStatus?.result === RequestResult.Fail) {
            setAcknowledgeErrorMessage(acknowledgeDocumentArchivesStatus.message as string);
            setIsSettingAcknowledged(false);
        }
    }, [acknowledgeDocumentArchivesStatus?.result]);

    useEffect(() => {
        if (resendEmailsStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: resendEmailsStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove resend status
            dispatch(captureResendEmailsStatus());
            // close the confirmation dialog and refresh
            onResendEmailsDialogClose();
            refreshList();
        } else if (resendEmailsStatus?.result === RequestResult.Fail) {
            setResendEmailsErrorMessage(resendEmailsStatus.message as string);
            setIsResendingEmails(false);
        }
    }, [resendEmailsStatus?.result]);

    // useEffect(() => {
    //     if (deleteDocumentArchivesStatus?.result === RequestResult.Success) {
    //         // close the delete dialog and refresh
    //         onDeleteDialogClose(true);
    //         refreshList();
    //         dispatch(setToastConfig({
    //             message: deleteDocumentArchivesStatus.message as string,
    //             severity: ToastSeverity.Success
    //         }));
    //     } else if (deleteDocumentArchivesStatus?.result === RequestResult.Fail) {
    //         setDeleteErrorMessage(deleteDocumentArchivesStatus.message as string);
    //     }
    // }, [deleteDocumentArchivesStatus?.result]);

    useEffect(() => {
        if (abandonDocumentArchivesStatus?.result === RequestResult.Success) {
            // close the delete/abandon dialog and refresh
            onAbandonDialogClose(true);
            refreshList();
            dispatch(setToastConfig({
                message: abandonDocumentArchivesStatus.message as string,
                severity: ToastSeverity.Success
            }));
        } else if (abandonDocumentArchivesStatus?.result === RequestResult.Fail) {
            setAbandonErrorMessage(abandonDocumentArchivesStatus.message as string);
        }
    }, [abandonDocumentArchivesStatus?.result]);

    useEffect(() => {
        setDocumentArchiveRows(getDocumentArchiveRows());

        if (!documentArchives && requestsInFlight > 0) {
            setIsLoading(true);
        } else if (requestsInFlight > 0) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [documentArchives, requestsInFlight]);

    useEffect(() => {
        // we have content, so lets properly size that content area        
        const titleHeight = document.getElementById('document-archives-title-comp')?.clientHeight || 0;
        // if filters are open, include that in the calculation
        let filterBarHeight = 0;
        if (filtersOpen) {
            filterBarHeight = document.getElementById('document-archives-filter-bar')?.clientHeight || 0;
        }
        let totalHeaderAreaHeight = titleHeight + filterBarHeight;
        if (totalHeaderAreaHeight > 0) {
            setContentAreaHeight(`calc(100% - ${totalHeaderAreaHeight}px)`);
        }
    }, [documentArchives, filtersOpen]);

    useEffect(() => {
        if (documentArchives) {
            let numFilters = 0;

            if (filterClientName && filterClientName.length > 0) {
                numFilters += 1;
            }
            if (filterDirection && filterDirection.length > 0) {
                numFilters += 1;
            }
            if (filterDocumentType && filterDocumentType.length > 0) {
                numFilters += 1;
            }
            if (filterPartnerName && filterPartnerName.length > 0) {
                numFilters += 1;
            }
            if (filterFileName && filterFileName.length > 0) {
                numFilters += 1;
            }
            if (filterControlNumber && filterControlNumber.length > 0) {
                numFilters += 1;
            }
            if (filterFunctionalGroupControlNumber && filterFunctionalGroupControlNumber.length > 0) {
                numFilters += 1;
            }
            if (filterTransactionControlNumber && filterTransactionControlNumber.length > 0) {
                numFilters += 1;
            }
            if (filterPurchaseOrder && filterPurchaseOrder.length > 0) {
                numFilters += 1;
            }
            if (filterErpOrderNumber && filterErpOrderNumber.length > 0) {
                numFilters += 1;
            }
            if (filterStatuses && filterStatuses.length > 0) {
                numFilters += 1;
            }
            if (filterDateFrom) {
                numFilters += 1;
            }
            if (filterDateTo) {
                numFilters += 1;
            }
            if (filterWasNotificationSent !== undefined) {
                numFilters += 1;
            }
            if (filterIsNotificationRequired === true) {
                numFilters += 1;
            }

            setFilterCount(numFilters);
        }
    }, [documentArchives, filterClientName, filterDirection, filterDocumentType, filterPartnerName, filterFileName, filterControlNumber, filterFunctionalGroupControlNumber, filterTransactionControlNumber, filterPurchaseOrder, filterErpOrderNumber, filterStatuses, filterDateFrom, filterDateTo, filterWasNotificationSent, filterIsNotificationRequired]);

    useEffect(() => {
        return () => {
            debounceOnSortingChanged.cancel();
        };
    }, [debounceOnSortingChanged]);

    const getSortRefreshInput = () => {
        return {
            after: undefined,
            limit: pageSize,
            clientName: filterClientName,
            direction: filterDirection,
            documentType: filterDocumentType,
            order: serverSortModel,
            partnerName: filterPartnerName,
            fileName: filterFileName,
            controlNumber: filterControlNumber,
            functionalGroupControlNumber: filterFunctionalGroupControlNumber,
            transactionControlNumber: filterTransactionControlNumber,
            purchaseOrder: filterPurchaseOrder,
            erpOrderNumber: filterErpOrderNumber,
            acknowledgementStatuses: filterStatuses,
            dateFrom: filterDateFrom,
            dateTo: filterDateTo,
            wasNotificationSent: filterWasNotificationSent,
            isNotificationRequired: filterIsNotificationRequired
        } as GetDocumentArchivesInput
    }

    useEffect(() => {
        if (!sortFirstLoad) {
            const sortRefreshData = getSortRefreshInput();
            if (debounceOn !== true) {
                onServerSortModelChanged(sortRefreshData);
            } else {
                debounceOnSortingChanged(sortRefreshData);
            }
        } else {
            setSortFirstLoad(false);
        }
    }, [serverSortModel]);
    

    const getDocumentArchiveRows = () => {
        if (documentArchives && documentArchives.length > 0) {
            return documentArchives.map((documentArchive) => {
                const {
                    id,
                    client,
                    controlNumber,
                    functionalGroupControlNumber,
                    transactionControlNumber,
                    purchaseOrder,
                    erpOrderNumber,
                    invoice,
                    documentType,
                    direction,
                    createdTime,
                    fileName,
                    acknowledgementStatus,
                    partner,
                    notifiedTime,
                    bulkNotify,
                    isAbandoned
                } = documentArchive;

                return {
                    _raw: documentArchive,
                    id,
                    clientName: client?.name,
                    clientId: client?.id,
                    controlNumber,
                    functionalGroupControlNumber,
                    transactionControlNumber,
                    PONumber: purchaseOrder,
                    erpOrderNumber,
                    invoice,
                    documentType,
                    direction,
                    createdDate: createdTime,
                    partnerName: partner?.name,
                    partnerId: partner?.id,
                    fileName,
                    status: acknowledgementStatus,
                    notifiedTime,
                    bulkNotify,
                    isAbandoned
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    }

    const onRowClientNameClick = (id: string | undefined) => {
        if (id) {
            let route = "/client/" + id;
            navigate(route);
        }
    };

    const onRowPartnerNameClick = (id: string | undefined) => {
        if (id) {
            let route = "/partner/" + id;
            navigate(route);
        }
    };

    const documentArchiveColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                headerName: 'CLIENT',
                field: 'clientName',
                flex: 2,
                cellClassName: "ediDataGridCellFirstChild",
                minWidth: 150,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const { value, row } = params;
                    const clientId = row.clientId;
                    
                    if (clientId) {
                        return (
                            <Link
                                component="button"
                                className="ediDataGridCellFirstChild ediDataGridCellOverflowHidden"
                                color={row.isAbandoned ? theme.palette.warning.light : theme.palette.primary.main}
                                onClick={() => {
                                    onRowClientNameClick(clientId);
                                }}
                            >
                                {value}
                            </Link>
                        );
                    } else {
                        return value;
                    }
                },
            }, {
                headerName: 'CONTROL #',
                field: 'controlNumber', 
                flex: 1,
                minWidth: 100,
                sortable: false,
            }, {
                headerName: 'FG #',
                field: 'functionalGroupControlNumber', 
                flex: 1,
                minWidth: 100,
                sortable: false,
            }, {
                headerName: 'TRANSACTION #',
                field: 'transactionControlNumber', 
                flex: 1,
                minWidth: 120,
                sortable: false,
            }, {
                headerName: 'PO #',
                field: 'PONumber',
                flex: 1,
                minWidth: 100,
                sortable: false,
            }, {
                headerName: 'ERP #',
                field: 'erpOrderNumber',
                flex: 1,
                minWidth: 100,
                sortable: false,
            }, {
                headerName: 'I/O',
                field: 'direction',
                width: 80,
                sortable: true,
            }, {
                headerName: 'TYPE',
                field: 'documentType',
                width: 100,
                sortable: true,
            }, {
                headerName: 'INVOICE',
                field: 'invoice',
                flex: 1,
                minWidth: 100,
                sortable: false,
            }, {
                headerName: 'CREATED DATE',
                field: 'createdDate',
                minWidth: 150,
                type: 'dateTime',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let dateValue = value ? getShortDateString(value) : undefined;
                    let timeValue = value ? getTimeString(value, { includeSeconds: true }) : undefined;
                    return (
                        <GridCellDualVert header={dateValue} sub={timeValue} />
                    )
                },
                sortable: true,
            }, {
                headerName: 'PARTNER',
                field: 'partnerName',
                minWidth: 150,
                flex: 2,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const { value, row } = params;
                    const partnerId = row.partnerId;
                    if (partnerId) {
                        return (
                            <Link
                                component="button"
                                variant="body2"
                                color={row.isAbandoned ? theme.palette.warning.light : theme.palette.primary.main}
                                className="ediDataGridCellOverflowHidden"
                                onClick={() => {
                                    onRowPartnerNameClick(partnerId);
                                }}
                            >
                                {value}
                            </Link>
                        );
                    } else {
                        return value;
                    }
                },
            }, {
                headerName: 'FILE NAME',
                field: 'fileName',
                flex: 2,
                minWidth: 150,
                sortable: false,
            }, {
                headerName: 'ACK STATUS',
                field: 'status',
                flex: 1,
                minWidth: 120,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    const displayValue = capitalizeWithUnderscoreRemoval(value);
                    return (displayValue);
                },
            }, {
                headerName: 'NOTIFIED TIME',
                field: 'notifiedTime',
                width: 114,
                sortable: false,
                type: 'dateTime',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let dateValue = value ? getShortDateString(value) : undefined;
                    let timeValue = value ? getTimeString(value, { includeSeconds: true }) : undefined;
                    return (
                        <GridCellDualVert header={dateValue} sub={timeValue} />
                    )
                },
            }, {
                headerName: 'BULK NOTIFY',
                field: 'bulkNotify',
                width: 110,
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let isNotify = value === true ? "Y" : "";
                    return (
                        isNotify
                    );
                },
                sortable: true,
            }, {
                headerName: 'ABANDONED',
                field: 'isAbandoned',
                cellClassName: 'ediDataGridCellWarning',
                width: 100,
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let abandoned = value === true ? "Y" : "";
                    return (
                        abandoned
                    );
                },
                sortable: true,
            }

        ],
        [],
    );

    const GetApiRef = () => {
        return useGridApiContext();
    }
    const loadingOverlay = () => {

        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the Document Archives' error={error}></ErrorMessage>
                )}
                {!error && (documentArchives && documentArchives.length > 0 && requestsInFlight > 0) && (
                    <CircularProgress aria-label={'progress spinner'} key={'logs-spinner'} size={42} sx={{ zIndex: 1 }} />
                )}
                {!error && (!documentArchives) && (
                    <MainDataGridLoadingSkeleton apiRef={GetApiRef()} rowBottomMargin={6} />
                )}
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the Document Archives' error={error}></ErrorMessage>
                )}
                {!error && (documentArchives && documentArchives.length <= 0 && filterCount > 0) && (
                    <NoResultsMessage topMargin={6} message="Try different filters to see results." />
                )}
            </GridOverlay>
        );
    };

    const gridToolbar = () => {
        
            return (
                <GridToolbarContainer>
                    {canEdit &&
                    <ToolbarButton
                        title="Manually Acknowledge Selected"
                        aria-label="Acknowledge"
                        size="small"
                        onClick={acknowledgeSelectedRows}
                        color='primary'
                        disabled={!(selectionModel && selectionModel.length > 0)}
                    >
                        Acknowledge
                    </ToolbarButton>
                    }
                    {
                    // <ToolbarButton
                    //     title="Delete Selected"
                    //     aria-label="Delete"
                    //     size="small"
                    //     onClick={deleteSelectedRows}
                    //     color='error'
                    //     disabled={!(selectionModel && selectionModel.length > 0)}
                    // >
                    //     Delete
                    // </ToolbarButton>
                    }
                    {canEdit &&
                    <ToolbarButton
                        title="Abandon Selected"
                        aria-label="Abandon"
                        size="small"
                        onClick={abandonSelectedRows}
                        color='error'
                        disabled={!(selectionModel && selectionModel.length > 0)}
                    >
                        Abandon
                    </ToolbarButton>
                    }
                    <ToolbarButton
                        title="Resend Email for Selected"
                        aria-label="Resend Email"
                        size="small"
                        onClick={resendEmailForSelectedRows}
                        color='primary'
                        disabled={!(selectionModel && selectionModel.length > 0)}
                    >
                        Resend Email
                    </ToolbarButton>
                </GridToolbarContainer>
            );
       
    };

    // const deleteSelectedRows = () => {
    //     setOpenDelete(true);
    // }

    const abandonSelectedRows = () => {
        setOpenAbandon(true);
    }

    const acknowledgeSelectedRows = () => {
        setOpenAcknowledgeConfirm(true);
    }

    const resendEmailForSelectedRows = () => {
        setOpenResendConfirm(true);
    }

    const refreshList = () => {
        // Refresh list to bring in potential updates
        loadPage(undefined);
        // if details is open, refresh the details too
        if (detailsOpen && detailsRowId) {
            dispatch(fetchDocumentArchiveById(detailsRowId));
        }
    }

    const onDialogClose = () => {
        // Clear error on close.
        dispatch(clearError());
    };

    // const onDeleteDialogConfirm = (id: string) => {
    //     // delete the selected rows
    //     // ignore the id passed in as it will be empty
    //     let ids = getSelectionRows();
    //     if (ids) {  
    //         dispatch(deleteDocumentArchives(ids));
    //     }
    // }

    // const onDeleteDialogClose = (deleted? : boolean) => {
    //     setOpenDelete(false);
    //     onDialogClose();
    //     dispatch(captureDeleteDocumentArchivesStatus());
    //     setDeleteErrorMessage('');
    //     if (detailsOpen && deleted) {
    //         // if we deleted from details, close the dialog
    //         onDetailsDialogClose();
    //     }
    // };

    const onAbandonDialogConfirm = (id: string) => {
        // abandon the selected rows
        // ignore the id passed in as it will be empty
        let ids = getSelectionRows();
        if (ids) {  
            dispatch(abandonDocumentArchives(ids));
        }
    }

    const onAbandonDialogClose = (abandoned? : boolean) => {
        setOpenAbandon(false);
        onSelectionModelChange([]); // de-select any selected rows
        onDialogClose();
        dispatch(captureAbandonDocumentArchivesStatus());
        setAbandonErrorMessage('');
        if (detailsOpen && abandoned) {
            // if we abandoned from details, close the dialog
            onDetailsDialogClose();
        }
    };

    const onAcknowledgeDialogClose = () => {
        setOpenAcknowledgeConfirm(false);
        onDialogClose();
        dispatch(captureAcknowledgeDocumentArchivesStatus());
        setIsSettingAcknowledged(false);
        setAcknowledgeErrorMessage('');
    };

    const onAcknowledgeArchivesConfirm = () => {
        // acknowledge the selected archives
        let ids = getSelectionRows();
        if (ids) {            
            setIsSettingAcknowledged(true);
            dispatch(acknowledgeDocumentArchives(ids));
        }
    };

    const onResendEmailsDialogClose = () => {
        setOpenResendConfirm(false);
        onDialogClose();
        dispatch(captureResendEmailsStatus());
        setIsResendingEmails(false);
        setResendEmailsErrorMessage('');
    };

    const onResendEmailsConfirm = () => {
        // resend email for the selected archives
        let ids = getSelectionRows();
        if (ids) {
            setIsResendingEmails(true);
            dispatch(resendEmails(ids));
        }
    };

    const getServerSortEntryFromGridSortEntry = (entry: GridSortItem) => {
        let newModel = {} as DocumentArchiveOrderByInput;
        switch (entry.field) {
            case 'createdDate':
                newModel.createdTime = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'direction':
                newModel.direction = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'documentType':
                newModel.documentType = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'bulkNotify':
                newModel.bulkNotify = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'isAbandoned':
                newModel.isAbandoned = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
        }
        return newModel;
    }

    const createdAndSetServerSortModel = (model: GridSortModel) => {
        if (model && model.length > 0) {
            let newArray = [] as DocumentArchiveOrderByInput[];
            let i = 0;
            for (i; i < model.length; ++i) {
                newArray.push(getServerSortEntryFromGridSortEntry(model[i]));
            }
            setServerSortModel(newArray);
        } else {
            setServerSortModel(undefined);
        }

    };

    const onSortModelChange = (model: GridSortModel) => {
        createdAndSetServerSortModel(model);
        setSortModel(model);
    };

    const onFiltersClick = () => {
        // Show/hide filters bar
        setFiltersOpen(!filtersOpen);
        // reset filters cleared to false since not clicking close button on bar at this point
        setFiltersCleared(false);
    };

    const onFilterBarClose = () => {
        // set filters as cleared
        setFiltersCleared(true);
        setFilterCount(0);
        // we don't want to close the filter bar since client is required
        setFiltersOpen(true);
    };

    const onServerSortModelChanged = (refreshInput: GetDocumentArchivesInput) => {
        dispatch(clearState());
        dispatch(fetchDocumentArchiveList(refreshInput));
    }

    const onSelectionModelChange = (currentSelectionModel: GridSelectionModel) => {
        setSelectionModel(currentSelectionModel);
    };

    const loadPage = (endEdge: string | undefined) => {
        dispatch(fetchDocumentArchiveList({
            after: endEdge,
            limit: pageSize,
            clientName: filterClientName,
            direction: filterDirection,
            documentType: filterDocumentType,
            order: serverSortModel,
            partnerName: filterPartnerName,
            fileName: filterFileName,
            controlNumber: filterControlNumber,
            functionalGroupControlNumber: filterFunctionalGroupControlNumber,
            transactionControlNumber: filterTransactionControlNumber,
            purchaseOrder: filterPurchaseOrder,
            erpOrderNumber: filterErpOrderNumber,
            acknowledgementStatuses: filterStatuses,
            dateFrom: filterDateFrom,
            dateTo: filterDateTo,
            wasNotificationSent: filterWasNotificationSent,
            isNotificationRequired: filterIsNotificationRequired
        }));
    };

    const handlePageLoad = () => {
        if (!documentArchivesPagingResult) {
            return;
        }
        if (!documentArchivesPagingResult.cursor?.nextPage) {
            return;
        }

        loadPage(documentArchivesPagingResult.cursor.nextPage);
    };

    const refreshFilters = (
        filterClient: string | undefined, 
        filterIO?: string | undefined, 
        filterDocType?: string | undefined,
        filterPartner?: string | undefined,
        filterControlNum?: string | undefined,
        filterFunctionalGroupControlNum?: string | undefined,
        filterTransactionControlNum?: string | undefined,
        filterPONum?: string | undefined,
        filterFileNameVal?: string | undefined,
        filterAcknowledgmentStatuses?: string[] | undefined,
        filterCreatedDateFrom?: DateTime | undefined,
        filterCreatedDateTo?: DateTime | undefined,
        filterNotificationSent?: boolean | undefined,
        filterNotificationRequired?: boolean | undefined,
        filterErpOrderNum?: string | undefined,
    ) => {
        // convert status string array back into DocumentAcknowledgementStatus enum values for correct query type
        let ackStatuses: DocumentAcknowledgementStatus[] = [];
        if (filterAcknowledgmentStatuses && filterAcknowledgmentStatuses.length > 0) {
            filterAcknowledgmentStatuses.forEach(status => {
                // put values back to all caps and add underscore back in where it was replaced with a space
                var allCapsStatus = status.replaceAll(' ', '_').toUpperCase();
                ackStatuses.push(allCapsStatus as DocumentAcknowledgementStatus);
            });
        }

        setFilterClientName(filterClient);
        setFilterDirection(filterIO);
        setFilterDocumentType(filterDocType);
        setFilterControlNumber(filterControlNum);
        setFilterFunctionalGroupControlNumber(filterFunctionalGroupControlNum);
        setFilterTransactionControlNumber(filterTransactionControlNum);
        setFilterPurchaseOrder(filterPONum);
        setFilterErpOrderNumber(filterErpOrderNum);
        setFilterPartnerName(filterPartner);
        setFilterFileName(filterFileNameVal);
        setFilterStatuses(ackStatuses);
        setFilterDateFrom(filterCreatedDateFrom);
        setFilterDateTo(filterCreatedDateTo);
        setFilterWasNotificationSent(filterNotificationSent);
        setFilterIsNotificationRequired(filterNotificationRequired);
        dispatch(clearState());
        
        // if in pre-made query for unset, the change of sort order will 
        // call fetch, so exit before calling fetch here to prevent
        // an initial search from firing prior to the sort being added
        if (premadeQuery && premadeQuery === "unsent" && waitForSort) {
            return;
        }

        dispatch(fetchDocumentArchiveList({
            after: undefined,
            limit: pageSize,
            clientName: filterClient,
            direction: filterIO,
            documentType: filterDocType,
            order: serverSortModel,
            partnerName: filterPartner,
            fileName: filterFileNameVal,
            controlNumber: filterControlNum,
            functionalGroupControlNumber: filterFunctionalGroupControlNum,
            transactionControlNumber: filterTransactionControlNum,
            purchaseOrder: filterPONum,
            erpOrderNumber: filterErpOrderNum,
            acknowledgementStatuses: ackStatuses,
            dateFrom: filterCreatedDateFrom,
            dateTo: filterCreatedDateTo,
            wasNotificationSent: filterNotificationSent,
            isNotificationRequired: filterNotificationRequired
        }));
       
    }

    const applyPreset = () => {
        if (premadeQuery) {
            if (premadeQuery === "rejected") {
                applyPresetRejectedFilter();
            } else if (premadeQuery === "unacknowledged") {
                applyPresetUnacknowledgedFilter();
            } else if (premadeQuery === "unsent") {
                applyPresetNotificationNotSentFilter();
            }
        }
    }

    const applyPresetUnacknowledgedFilter = () => {
        setPremadeFilter({
            filterAcknowledgmentStatuses: [DocumentAcknowledgementStatus.Unacknowledged],
            filterCreatedDateTo: documentArchiveUnacknowledgedFilterDate,
            filterIO: "O"
        } as PremadeDocumentArchiveFilterSet);
    }

    const applyPresetRejectedFilter = () => {
        setPremadeFilter({
            filterAcknowledgmentStatuses: [DocumentAcknowledgementStatus.Rejected, DocumentAcknowledgementStatus.PartialRejected],
            filterIO: "O"
        } as PremadeDocumentArchiveFilterSet);
    }

    const applyPresetNotificationNotSentFilter = () => {
        // set combination of notSent and notification required
        // since for the preset, we only want to see required ones
        setPremadeFilter({
            filterWasNotificationSent: false,
            filterIsNotificationRequired: true
        } as PremadeDocumentArchiveFilterSet);

        // default to sorting by bulkNotify desc to display those first
        // along with the usual createdDate
        // wrapped in a timeout to give time for the pre-made filter
        // values to be set in state first since the change of sort will 
        // trigger a search refresh/fetch and we want to wait for that search with the new sort
        setWaitForSort(true);
        setTimeout(() => {
            var sortModel: GridSortModel = [];
            sortModel.push({field: 'bulkNotify', sort: 'desc'});
            sortModel.push({field: 'createdDate', sort: 'desc'});
            onSortModelChange(sortModel);
            // after sort has been added to trigger a new search, reset flag for future searching
            setWaitForSort(false);
        }, 100);
    }

    const onRowClickHandler = (params: GridRowParams) => {
        if (params && params.id) {
            setDetailsRowId(params.id as string);
            setDetailsOpen(true);
        }
    }

    const onDetailsDialogClose = () => {
        setDetailsRowId(undefined);
        setDetailsOpen(false);
    }

    const onDetailsDialogResend = (rowId: string) => {
        if (rowId && rowId === detailsRowId) {
            setOpenResendConfirm(true);
        }
    }
    const onDetailsDialogAcknowledge = (rowId: string) => {
        if (rowId && rowId === detailsRowId) {
            setOpenAcknowledgeConfirm(true);
        }
    }

    // const onDetailsDialogDelete = (rowId: string) => {
    //     if (rowId && rowId === detailsRowId) {
    //         setOpenDelete(true);
    //     }
    // }

    const onDetailsDialogAbandon = (rowId: string) => {
        if (rowId && rowId === detailsRowId) {
            setOpenAbandon(true);
        }
    }

    const getAcknowledgeConfirmationMessage = () => {
        if (detailsOpen) {
            // if the details dialog is open, then the request came from there and it is a single request
            return `Are you sure you want to acknowledge this archive?`
        } else {
            // if not, the request is based off the selected rows 
            return "Are you sure you want to acknowledge the selected archives?";
        }
    }

    // const getDeleteConfirmationMessage = () => {
    //     if (detailsOpen) {
    //         // if the details dialog is open, then the request came from there and it is a single request
    //         return `Are you sure you want to delete this archive?`
    //     } else {
    //         // if not, the request is based off the selected rows 
    //         return `Are you sure you want to delete the ${selectionModel?.length} selected archives?`;
    //     }
    // }

    const getAbandonConfirmationMessage = () => {
        if (detailsOpen) {
            // if the details dialog is open, then the request came from there and it is a single request
            return `Are you sure you want to abandon this archive?`
        } else {
            // if not, the request is based off the selected rows 
            return `Are you sure you want to abandon the ${selectionModel?.length} selected archives?`;
        }
    }

    const getResendConfirmationMessage = () => {
        if (detailsOpen) {
            // if the details dialog is open, then the resend came from there and it is a single request
            return `Are you sure you want to resend the email for this archive?`
        } else {
            // if not, the request is based off the selected rows            
           return `Are you sure you want to resend emails for the ${selectionModel?.length} selected archives?`;
        }
    }

    const getSelectionRows = () => {
        if (detailsOpen) {
            // if the details dialog is open, then the resend came from there and it is a single request
            if (detailsRowId) {
                return [detailsRowId];
            }
        } else {
            // if not, the request is based off the selected rows            
            if (selectionModel && selectionModel.length > 0) {
                return selectionModel.map(rowId => rowId.toString());
            }
        }
    }
    
    return (
        <MainContentBox>
            <PageTitleBar text='Document Archives' id="document-archives-title-comp">
                <PageTitleToolbarGrid item>
                    <FiltersButton
                        onClick={onFiltersClick}
                        filterCount={filterCount}
                        filtersCleared={filtersCleared}
                        disabled={isLoading}
                        aria-label="filter button"
                        data-cy="filters"
                    />
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <DocumentArchivesFilterBar
                id="document-archives-filter-bar"
                visible={filtersOpen}
                loading={isLoading}
                viewer={viewer}
                premadeSet={premadeFilter}
                onFilterChanged={refreshFilters}
                onClose={onFilterBarClose}
            />
            <DataGridListScrollBox scrollheight={contentAreaHeight}>
                <MainDataGrid
                    loading={isLoading}
                    rowHeight={52}
                    aria-label="Document Archives List"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    checkboxSelection
                    rows={documentArchiveRows ?? []}
                    columns={documentArchiveColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    isRowSelectable={(params: GridRowParams) => params.row.isAbandoned !== true}
                    selectionModel={selectionModel}
                    onSelectionModelChange={onSelectionModelChange}
                    onRowsScrollEnd={handlePageLoad}
                    disableSelectionOnClick
                    onRowClick={onRowClickHandler}
                    getRowClassName={(params) => {
                        if (params.row.isAbandoned === true) {
                            return 'ediDataGridCellDisabled';
                        }
                            return '';
                    }}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        Toolbar: gridToolbar,
                    }}
                />
            </DataGridListScrollBox>
            <DeleteDialog
                isOpen={openAbandon}
                id={''}
                heading={'Abandon Document Archives'}
                message={getAbandonConfirmationMessage()}
                confirmButtonText='Abandon'
                onConfirm={onAbandonDialogConfirm}
                onReject={onAbandonDialogClose}
                errorMessage={abandonErrorMessage}
            />
            <Dialog
                open={openAcknowledgeConfirm}>
                <ConfirmationPrompt
                    heading="Acknowledge Document Archives"
                    message={getAcknowledgeConfirmationMessage()}
                    handleConfirm={onAcknowledgeArchivesConfirm}
                    handleReject={onAcknowledgeDialogClose}
                    isBusy={isSettingAcknowledged}
                    errorMessage={acknowledgeErrorMessage}
                />
            </Dialog>
            <Dialog
                open={openResendConfirm}>
                <ConfirmationPrompt
                    heading="Resend Email"
                    message={getResendConfirmationMessage()}
                    handleConfirm={onResendEmailsConfirm}
                    handleReject={onResendEmailsDialogClose}
                    isBusy={isResendingEmails}
                    errorMessage={resendEmailsErrorMessage}
                />
            </Dialog>
            <DocumentArchiveDetailsDialog 
                isOpen={detailsOpen}
                documentArchiveRecordId={detailsRowId}
                userCanEdit={canEdit}
                onAcknowledge={onDetailsDialogAcknowledge}
                onClose={onDetailsDialogClose}
                onAbandon={onDetailsDialogAbandon}
                onResendEmail={onDetailsDialogResend}
                onClientNameClick={onRowClientNameClick}
                onPartnerNameClick={onRowPartnerNameClick}
                error={error}
            />
        </MainContentBox>
    );
}

export default DocumentArchives;