import { PagingResultModelOfDocumentArchiveModel, DeleteMultipleByIdsPayload, ActionPayload, DocumentArchiveModel } from "../../gql-types.generated";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    documentArchivePagingResult?: PagingResultModelOfDocumentArchiveModel;
    documentArchiveList?: DocumentArchiveModel[];
    documentArchiveRequestsInFlight: number;
    deleteDocumentArchivesStatus?: DeleteMultipleByIdsPayload;
    abandonDocumentArchivesStatus?: ActionPayload;
    acknowledgeDocumentArchivesStatus?: ActionPayload;
    resendEmailsStatus?: ActionPayload;
    resendDocumentStatus?: ActionPayload;
    getDocumentHtmlFileStatus?: ActionPayload;
    documentArchiveByIdResult?: DocumentArchiveModel;
};

const initialState: SliceState = {
    error: undefined,
    documentArchivePagingResult: undefined,
    documentArchiveList: undefined,
    documentArchiveRequestsInFlight: 0,
    deleteDocumentArchivesStatus: undefined,
    abandonDocumentArchivesStatus: undefined,
    acknowledgeDocumentArchivesStatus: undefined,
    resendEmailsStatus: undefined,
    resendDocumentStatus: undefined,
    getDocumentHtmlFileStatus: undefined,
    documentArchiveByIdResult: undefined,
};

export const slice = createSlice({
    name: 'documentArchives',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.documentArchiveList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.documentArchiveRequestsInFlight = 0;
            // eslint-disable-next-line no-param-reassign
            state.documentArchivePagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteDocumentArchivesStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.abandonDocumentArchivesStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.acknowledgeDocumentArchivesStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.resendEmailsStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.resendDocumentStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.documentArchiveByIdResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.getDocumentHtmlFileStatus = undefined;
        },

        fetchDocumentArchiveListSuccess: (state, action: PayloadAction<PagingResultModelOfDocumentArchiveModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.documentArchivePagingResult = action.payload;
            let archives = state.documentArchiveList;
            if (!archives || !state.documentArchivePagingResult.cursor?.previousPage) {
                archives = [];
            }
            if (action.payload.nodes) {
                action.payload.nodes.map(node => {
                    if (!archives?.some(i => i.id === node?.id)) {
                        archives?.push(node as DocumentArchiveModel)
                    }
                    return node;
                });
            }
            // eslint-disable-next-line no-param-reassign
            state.documentArchiveList = archives;
        },

        incrementDocumentArchiveRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            state.documentArchiveRequestsInFlight += 1;
        },

        decrementDocumentArchiveRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            if (state.documentArchiveRequestsInFlight > 0) state.documentArchiveRequestsInFlight -= 1;
        },

        captureDeleteDocumentArchivesStatus: (state, action: PayloadAction<DeleteMultipleByIdsPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteDocumentArchivesStatus = action.payload;
        },

        captureAbandonDocumentArchivesStatus: (state, action: PayloadAction<ActionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.abandonDocumentArchivesStatus = action.payload;
        },

        captureAcknowledgeDocumentArchivesStatus: (state, action: PayloadAction<ActionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.acknowledgeDocumentArchivesStatus = action.payload;
        },

        captureResendEmailsStatus: (state, action: PayloadAction<ActionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.resendEmailsStatus = action.payload;
        },

        captureResendDocumentStatus: (state, action: PayloadAction<ActionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.resendDocumentStatus = action.payload;
        },

        fetchDocumentArchiveByIdSuccess: (state, action: PayloadAction<DocumentArchiveModel | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.documentArchiveByIdResult = action.payload;
        },

        captureGetDocumentHtmlFileStatus: (state, action: PayloadAction<ActionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.getDocumentHtmlFileStatus = action.payload;
        },
    },

});

export const selectError = (state: RootState): Error | undefined => state.documentArchives.error;
export const selectDocumentArchiveList = (state: RootState): DocumentArchiveModel[] | undefined => state.documentArchives.documentArchiveList;
export const selectRequestsInFlight = (state: RootState): number => state.documentArchives.documentArchiveRequestsInFlight;
export const selectDocumentArchivePagingResult = (state: RootState): PagingResultModelOfDocumentArchiveModel | undefined => state.documentArchives.documentArchivePagingResult;
export const selectDeleteDocumentArchivesStatus = (state: RootState): DeleteMultipleByIdsPayload | undefined => state.documentArchives.deleteDocumentArchivesStatus;
export const selectAbandonDocumentArchivesStatus = (state: RootState): ActionPayload | undefined => state.documentArchives.abandonDocumentArchivesStatus;
export const selectAcknowledgeDocumentArchivesStatus = (state: RootState): ActionPayload | undefined => state.documentArchives.acknowledgeDocumentArchivesStatus;
export const selectResendEmailsStatus = (state: RootState): ActionPayload | undefined => state.documentArchives.resendEmailsStatus;
export const selectResendDocumentStatus = (state: RootState): ActionPayload | undefined => state.documentArchives.resendDocumentStatus;
export const selectGetDocumentHtmlFileStatus = (state: RootState): ActionPayload | undefined => state.documentArchives.getDocumentHtmlFileStatus;
export const selectDocumentArchiveById = (state: RootState): DocumentArchiveModel | undefined => state.documentArchives.documentArchiveByIdResult;

export const {
    clearError,
    fetchError,
    clearState,
    fetchDocumentArchiveListSuccess,
    incrementDocumentArchiveRequestsInFlight,
    decrementDocumentArchiveRequestsInFlight,
    captureDeleteDocumentArchivesStatus,
    captureAbandonDocumentArchivesStatus,
    captureAcknowledgeDocumentArchivesStatus,
    captureResendEmailsStatus,
    captureResendDocumentStatus,
    fetchDocumentArchiveByIdSuccess,
    captureGetDocumentHtmlFileStatus
} = slice.actions;

export default slice.reducer;