import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { queryMessagingLogById } from '../../gql/QueryMessagingLogById';
import { fetchError, fetchMessagingLogByIdSuccess, captureUpdateLogStatus } from './MessageLogDetailsSlice';
import { MessagingLogModel, RequestResult, DocumentStatus } from '../../gql-types.generated';
import { mutationExecuteEdiActionSvcAction } from '../../gql/MutationExecuteEdiActionSvcAction';
import { getErrorMessage } from '../../util/Common';

export const fetchMessagingLogById = (id: string) => 
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const errorLog = await queryMessagingLogById(gqlClient, id);
            if (errorLog) {
                dispatch(fetchMessagingLogByIdSuccess(errorLog as MessagingLogModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    }


export const updateMessagingLogDocumentStatus = (id: string, status: DocumentStatus): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            let actionArguments = `{MessagingLogId: ${JSON.stringify(id)}, Status: ${JSON.stringify(status)}}`;
            const updateStatus = await mutationExecuteEdiActionSvcAction(gqlClient, {
                actionName: "UpdateMessagingLogStatusAction",
                actionArguments: actionArguments
            });
            if (updateStatus) {
                if (updateStatus.result === RequestResult.Fail) {
                    getErrorMessage(updateStatus.message as string, updateStatus.errors, true, true);
                }
                dispatch(captureUpdateLogStatus(updateStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };