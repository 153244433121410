import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import { PagingResultModelOfMessagingLogModel, GetMessagingLogsInput, MessagingLogListQuery } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_MESSAGING_LOG_LIST = gql`
    query messagingLogList($input: GetMessagingLogsInput!) {
        messagingLogs (input: $input) {
            cursor {
                nextPage
                previousPage
              }
            nodes {
                id
                documentId
                description
                direction
                documentDate
                documentType
                documentReferenceId
                fileName
                internalClientCode
                internalPartnerCode
                invoice
                isAcknowledged
                messageType
                originalFileName
                pO
                processName
                receiverId
                senderId
                status
                totalLines
                totalQuantity
                totalValue
                createdTime
                client {
                    id
                    name
                }
                partner {
                    id
                    name
                }
                clientPartner {
                    id
                }
            }
        }
    }
`;

export const queryMessagingLogList = async (
    client: ApolloClient<NormalizedCacheObject>,
    filters?: GetMessagingLogsInput
): Promise<PagingResultModelOfMessagingLogModel | undefined> => {
    const { after, limit, clientName, direction, documentType, order, partnerName, processName, status, documentId, pO, dateFrom, dateTo } = filters ?? {};
    const { data, errors } = await client.query<MessagingLogListQuery>({
        variables: {
            input: {
                after,
                limit,
                clientName: clientName?.trim(),
                direction,
                documentType: documentType?.trim(),
                order,
                partnerName: partnerName?.trim(),
                processName,
                status,
                documentId: documentId?.trim(),
                pO: pO?.trim(),
                dateFrom,
                dateTo
            }
        },
        query: QUERY_MESSAGING_LOG_LIST,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.messagingLogs as PagingResultModelOfMessagingLogModel;
}