import { useCallback, useEffect, useState } from 'react'; 
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { styled, Button, Grid, Link, Typography } from '@mui/material';
import { Viewer } from "../../util/Constants";
import { useTitle } from '../../util/Common';
import { clearState, selectError, selectMessageLog, captureUpdateLogStatus, selectUpdateLogStatus } from './MessageLogDetailsSlice';
import { fetchMessagingLogById, updateMessagingLogDocumentStatus } from './MessageLogDetailsActions';
import { setLocalStorageItem, viewerCanEdit } from '../../util/ViewerUtility';
import { getFormattedDateTimeString } from '../../util/DateTimeUtility';
import { DetailsTabContainer, DetailsTabHeader, DetailsTabHeaderWrapper, DetailsTabPanelContainer, CardAvatar, TabContainer, TabHeader } from '../../util/SharedStyles';
import TabPanel from '../../components/TabPanel';
import DetailsPage from '../../components/DetailsPage';
import ErrorIcon from '@mui/icons-material/Error';
import { DocumentStatus, RequestResult } from '../../gql-types.generated';
import { setToastConfig } from '../../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../util/Constants';

const TabContainerWithPadding = styled(TabContainer)(() => ({
    padding: '20px',
    overflowY: 'auto',
}));

const TypographyWithReturns = styled(Typography)((props) => ({
    whiteSpace: 'pre-line',
}));

interface MessageLogDetailsProps {
    viewer: Viewer | undefined;
}

const MessageLogDetails: React.FC<MessageLogDetailsProps> = (props) => {
    // messageId will be passed in through router
    const { messageId } = useParams();
    const {viewer} = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);
    const messageLog = useAppSelector(selectMessageLog);
    const error = useAppSelector(selectError);
    const updateStatus = useAppSelector(selectUpdateLogStatus);


    // detailsPageProps
    const [detailsRecordLoaded, setDetailsRecordLoaded] = useState<boolean>(false);
    const [detailsPageTitle, setDetailsPageTitle] = useState<string | undefined>(undefined);
    const [detailsAV, setDetailsAV] = useState<JSX.Element | undefined>(undefined);
    const [detailsToolbarButtons, setDetailsToolbarButtons] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsHeader, setDetailsHeader] = useState<string | undefined>(undefined);
    const [verticalAlign, setVerticalAlign] = useState<boolean>(false);
    const [totalLines, setTotalLines] = useState<string | undefined>(undefined);

    const canEdit = viewerCanEdit(viewer);

    const localStorageTabIndexName = "messageLogDetailsTabIndex_" + messageId;

    useEffect(() => {
        if (messageId) {
            dispatch(fetchMessagingLogById(messageId));
        }
        return () => {
            dispatch(clearState());
        }
    },[]);

    useTitle(detailsPageTitle);
 

    const getMessageAV = useCallback(() => () => {
        if (messageLog?.status) {
            if (messageLog.status === DocumentStatus.Error) {
                return (
                    <CardAvatar aria-label="error" alt="error">
                        <ErrorIcon />
                    </CardAvatar>
                );
            }
        }
    }, [messageLog?.status]);

    const getToolbarButtons = useCallback(() => () => {
        if (messageLog?.status && canEdit) {
            if (messageLog.status === DocumentStatus.Error) {
                return (
                    [<Button
                        variant="outlined"
                        color="primary"
                        onClick={onResolveError}
                        key="resolve-error-button"
                    >
                        Resolve Error
                    </Button>]
                );
            } else if (messageLog.status === DocumentStatus.ResolvedError) {
                return (
                    [<Button
                        variant="outlined"
                        color="primary"
                        onClick={onUnresolveError}
                        key="unresolve-error-button"
                    >
                        Unresolve Error
                    </Button>]
                );
            }
        }
        return undefined;
    }, [messageLog?.status, canEdit]);

    useEffect(() => {
        setDetailsRecordLoaded(!!messageLog);        
        if (messageLog) {
            if (messageLog.status) {
                setDetailsAV(getMessageAV());                
                setDetailsHeader(messageLog.status.replaceAll('_', ' ')); 
                setDetailsToolbarButtons(getToolbarButtons());
            }
            if (messageLog.messageType){
                setDetailsPageTitle(`${messageLog.messageType} ${messageId}`);
            } else {
                setDetailsPageTitle(messageId);
            }
            if (messageLog.totalLines || messageLog.totalLines === 0) {
                setTotalLines(messageLog.totalLines.toString());
            }
        }
    }, [messageLog, messageId, error, getMessageAV, getToolbarButtons]);

    useEffect(() => {
        if (updateStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: updateStatus.message as string,
                severity: ToastSeverity.Success
            }));
            dispatch(captureUpdateLogStatus());
            // navigate back to the list page
            navigate('/eventlogs');
        } else if (updateStatus?.result === RequestResult.Fail) {
            dispatch(setToastConfig({
                message: updateStatus.message as string,
                severity: ToastSeverity.Error
            }));
            dispatch(captureUpdateLogStatus());
            // navigate back to the list page
            navigate('/eventlogs');
        }
    }, [updateStatus?.result]);

    const onResolveError = () => {
        dispatch(captureUpdateLogStatus());
        if (messageId) {
            dispatch(updateMessagingLogDocumentStatus(messageId, DocumentStatus.ResolvedError));
        }
    };

    const onUnresolveError = () => {
        dispatch(captureUpdateLogStatus());
        if (messageId) {
            dispatch(updateMessagingLogDocumentStatus(messageId, DocumentStatus.Error));
        }
    };

    const updateAlignment = (verticalAlign: boolean) => {
        setVerticalAlign(verticalAlign);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        // save off the latest selected tab so that if user navigates to another
        // screen and comes back, their last active tab is remembered
        setLocalStorageItem(viewer, localStorageTabIndexName, newValue.toString());
    };

    const onClientNameClick = (id: string | undefined) => {
        if (id) {
            let route = "/client/" + id;
            navigate(route);
        }
    };

    const onPartnerNameClick = (id: string | undefined) => {
        if (id) {
            let route = "/partner/" + id;
            navigate(route);
        }
    };

    const onDocumentTypeClick = (clientPartnerId: string | undefined) => {
        // navigate to the clientPartner to allow the user to view the associated transaction/documentType
        if (clientPartnerId) {
            let route = "/clientpartner/" + clientPartnerId;
            navigate(route);
        }
    };

    const getLogMessage = () => {
        if (messageLog && messageLog.rawMessage) {
            let parsed = JSON.parse(messageLog.rawMessage);
            if (parsed) {
                let parsedEntries = Object.entries(parsed);
                if (parsedEntries) {
                    return (
                        <TabContainerWithPadding>
                            <Grid container alignItems="flex-start">
                                <Grid container spacing={2} columns={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                                    {parsedEntries.map(([key, value], index) => (
                                        <Grid item xs={1} key={`message-property-${index}`}>
                                            <Typography variant='caption' >{key}</Typography>
                                            <TypographyWithReturns variant='body1' >{value as string}</TypographyWithReturns>
                                        </Grid>
                                    ))}
                                </ Grid>
                            </ Grid>
                        </TabContainerWithPadding>
                    );
                }
            }
        }
    };

    const getDocumentTypeDisplay = () => {
        let docType = messageLog?.documentType;
        if (messageLog && docType) {
            let clientPartnerId = messageLog.clientPartner?.id;
            if (clientPartnerId) {
                return (
                    <div>
                        <Link
                            component="button"
                            variant="body1"
                            onClick={() => {
                                onDocumentTypeClick(clientPartnerId);
                            }}
                        >
                            {docType}
                        </Link>
                    </div>
                );
                
            } else {
                return (
                    <Typography variant='body1' >{docType}</Typography>
                )
            }
        }
    };

    const getLogDetails = () => {
        if (messageLog) {
            let clientName = messageLog.client?.name;
            let partnerName = messageLog.partner?.name;
            return (
                <Grid container alignItems="flex-start">
                    <Grid container spacing={2} columns={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                        {clientName && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Client</Typography>
                                <div>
                                    <Link
                                        component="button"
                                        variant="body1"
                                        onClick={() => {
                                            onClientNameClick(messageLog.client?.id);
                                        }}
                                    >
                                        {clientName}
                                    </Link>
                                </div>
                            </Grid>
                        )}
                        {partnerName && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Partner</Typography>
                                <div>
                                    <Link
                                        component="button"
                                        variant="body1"
                                        onClick={() => {
                                            onPartnerNameClick(messageLog.partner?.id);
                                        }}
                                    >
                                        {partnerName}
                                    </Link>
                                </div>
                            </Grid>
                        )}
                        {messageLog.createdTime && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Time Reported</Typography>
                                <Typography variant='body1' >{getFormattedDateTimeString(messageLog.createdTime)}</Typography>
                            </Grid>
                        )}
                        {messageLog.direction && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Direction</Typography>
                                <Typography variant='body1' >{messageLog.direction === "I" ? "INBOUND":"OUTBOUND"}</Typography>
                            </Grid>
                        )}
                        {messageLog.processName && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Process</Typography>
                                <Typography variant='body1' >{messageLog.processName}</Typography>
                            </Grid>
                        )}
                        {messageLog.description && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Description</Typography>
                                <Typography variant='body1' >{messageLog.description}</Typography>
                            </Grid>
                        )}
                        {messageLog.documentId && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Document Id</Typography>
                                <Typography variant='body1' >{messageLog.documentId}</Typography>
                            </Grid>
                        )}
                        {messageLog.documentDate && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Document Date</Typography>
                                <Typography variant='body1' >{getFormattedDateTimeString(messageLog.documentDate)}</Typography>
                            </Grid>
                        )}
                        {messageLog.documentType && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Document Type</Typography>
                                {getDocumentTypeDisplay()}
                            </Grid>
                        )}
                        {messageLog.documentReferenceId && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Document Reference Id</Typography>
                                <Typography variant='body1' >{messageLog.documentReferenceId}</Typography>
                            </Grid>
                        )}
                        {messageLog.fileName && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >File Name</Typography>
                                <Typography variant='body1' >{messageLog.fileName}</Typography>
                            </Grid>
                        )}
                        {messageLog.internalClientCode && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Client Code(internal)</Typography>
                                <Typography variant='body1' >{messageLog.internalClientCode}</Typography>
                            </Grid>
                        )}
                        {messageLog.internalPartnerCode && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Partner Code(internal)</Typography>
                                <Typography variant='body1' >{messageLog.internalPartnerCode}</Typography>
                            </Grid>
                        )}
                        {messageLog.invoice && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Invoice</Typography>
                                <Typography variant='body1' >{messageLog.invoice}</Typography>
                            </Grid>
                        )}
                        {messageLog.originalFileName && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Original File Name</Typography>
                                <Typography variant='body1' >{messageLog.originalFileName}</Typography>
                            </Grid>
                        )}
                        {messageLog.pO && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Purchase Order(s)</Typography>
                                <Typography variant='body1' >{messageLog.pO}</Typography>
                            </Grid>
                        )}
                        {messageLog.receiverId && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Receiver</Typography>
                                <Typography variant='body1' >{messageLog.receiverId}</Typography>
                            </Grid>
                        )}
                        {messageLog.senderId && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Sender</Typography>
                                <Typography variant='body1' >{messageLog.senderId}</Typography>
                            </Grid>
                        )}
                        {totalLines && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Total Quantity</Typography>
                                <Typography variant='body1' >{totalLines}</Typography>
                            </Grid>
                        )}
                        {messageLog.totalQuantity && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Total Quantity</Typography>
                                <Typography variant='body1' >{messageLog.totalQuantity}</Typography>
                            </Grid>
                        )}
                        {messageLog.totalValue && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Total Value</Typography>
                                <Typography variant='body1' >{messageLog.totalValue}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )
        }
    }

    const getTabbedContainer = () => {
        return (
            <DetailsTabContainer>
                <DetailsTabHeaderWrapper>
                    <DetailsTabHeader
                        value={tabValue}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable error tabs"
                    >
                        <TabHeader label="Message" id='vertical-tab-error-details-message' />
                    </DetailsTabHeader>
                </DetailsTabHeaderWrapper>
                <DetailsTabPanelContainer>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={0}>
                        {getLogMessage()}
                    </TabPanel>
                </DetailsTabPanelContainer>
            </DetailsTabContainer>
        );
    }

    return (
        <DetailsPage
            recordLoaded={detailsRecordLoaded}
            pageTitle={detailsPageTitle}
            detailsAV={detailsAV}
            detailsHeader={detailsHeader}
            detailsSubHeader={undefined}
            detailsChips={undefined}
            getDetailsListContainer={getLogDetails}
            getTabbedContainer={getTabbedContainer}
            error={error}
            updateAlignment={updateAlignment}
            toolBarButtons={detailsToolbarButtons}
            detailsEditButton={undefined}
            detailsFavoriteButton={undefined}
            getDialogs={()=> undefined}
        />
    );
}

export default MessageLogDetails;