import { EntityType, FavoriteModel, UserRole } from '../gql-types.generated';
import { Viewer } from './Constants';

export const getEntityFavorite = (viewerFavorites: FavoriteModel[] | undefined, entityId: string | undefined, entityType: EntityType): FavoriteModel | undefined => {

    // If the given entity is found in the favorites, then return the favorite
    if (entityId && viewerFavorites && viewerFavorites.length > 0) {
        let favorite = viewerFavorites.find(f => f?.entityId === entityId && f?.entityType === entityType);
        return favorite;
    }
    return undefined;
}

/*
    LocalStorage
    desire is to have localStorage be user-specific, so these utility methods are to
    add a user id to the key before storing and then use same built key to retrieve/remove 
*/
export const setLocalStorageItem = (activeUser: Viewer | undefined, key: string, value: string) => {
    let userKey = getUserKey(activeUser, key);
    localStorage.setItem(userKey, value);
}

export const getLocalStorageItem = (activeUser: Viewer | undefined, key: string) => {
    let userKey = getUserKey(activeUser, key);
    return localStorage.getItem(userKey);
}

export const getLocalStorageItemAsBoolean = (activeUser: Viewer | undefined, key: string) => {
    let userKey = getUserKey(activeUser, key);
    let returnVal = localStorage.getItem(userKey);

    return Boolean(returnVal);
}

export const removeLocalStorageItem = (activeUser: Viewer | undefined, key: string) => {
    let userKey = getUserKey(activeUser, key);
    localStorage.removeItem(userKey);
}

const getUserKey = (activeUser: Viewer | undefined, key: string) => {
    let userKey = `EDI_${key}`;
    if (activeUser?.id) {
        userKey = `${activeUser.id}_${userKey}`;
    }
    return userKey;
}

export const viewerCanEdit = (viewer: Viewer | UserRole | undefined) => {
    if (viewer) {
        let role;
        if (viewer.hasOwnProperty("role")) {
            role = (viewer as Viewer).role;
        } else {
            role = viewer as UserRole;
        }
        if (role) {
            return role === UserRole.Admin || role === UserRole.Editor;
        }
    }
    return false;
}

export const viewerCanAddDelete = (viewer: Viewer | UserRole | undefined) => {
    if (viewer) {
        let role;
        if (viewer.hasOwnProperty("role")) {
            role = (viewer as Viewer).role;
        } else {
            role = viewer as UserRole;
        }
        if (role) {
            return role === UserRole.Admin;
        }
    }
    return false;
}