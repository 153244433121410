import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ClientContactModel,
    ClientModel,
    ClientPartnerModel,
    DeleteClientContactPayload,
    AttachmentModel,
    NoteModel,
    UpsertClientContactPayload,
    UpsertClientPayload,
    PagingResultModelOfClientModel,
    DeleteByIdPayload,
    AddClientPartnerPayload,
    UpsertClientAttachmentPayload,
    PagingResultModelOfAttachmentModel,
    UpsertEdiXmlConfigPayload,
    ClientTransactionModel,
    UpsertClientTransactionPayload,
    TransactionModel,
    ClientAkaModel,
    UpsertClientAkaPayload,
    BillingAggregatesModel,
    UpsertClientNotificationRecipientsPayload,
    ClientNotificationRecipientsModel,
    NotificationFormatterModel
} from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    clientPagingResult?: PagingResultModelOfClientModel;
    client?: ClientModel;
    clientContactInfo?: ClientContactModel[];
    tradingPartners?: ClientPartnerModel[];
    clientNotes?: NoteModel[];
    clientAttachments?: AttachmentModel[];
    clientAKAs?: ClientAkaModel[];
    clientTransactions?: ClientTransactionModel[];
    upsertClientStatus?: UpsertClientPayload;
    deleteClientStatus?: DeleteByIdPayload;
    upsertClientNoteStatus?: UpsertClientPayload;
    deleteClientNoteStatus?: DeleteByIdPayload;
    upsertClientAttachmentsStatus?: UpsertClientAttachmentPayload;
    deleteClientAttachmentStatus?: DeleteByIdPayload;
    upsertClientAkaStatus?: UpsertClientAkaPayload;
    deleteClientAkaStatus?: DeleteByIdPayload;
    upsertClientContactStatus?: UpsertClientContactPayload;
    deleteClientContactStatus?: DeleteClientContactPayload;
    upsertClientContactNoteStatus?: UpsertClientContactPayload;
    deleteClientContactNoteStatus?: DeleteByIdPayload;
    addClientPartnerStatus?: AddClientPartnerPayload;
    attachmentsPagingResult?: AttachmentModel[];
    upsertEdiXmlConfigStatus?: UpsertEdiXmlConfigPayload,
    deleteEdiXmlConfigStatus?: DeleteByIdPayload,
    upsertClientTransactionStatus?: UpsertClientTransactionPayload,
    deleteClientTransactionStatus?: DeleteByIdPayload,
    transactions?: TransactionModel[],
    deleteClientPartnerStatus?: DeleteByIdPayload,
    billingAggregates?: BillingAggregatesModel[],
    clientNotificationRecipients?: ClientNotificationRecipientsModel[],
    upsertClientNotificationRecipientsStatus?: UpsertClientNotificationRecipientsPayload,
    deleteClientNotificationRecipientsStatus?: DeleteByIdPayload,
    notificationFormatters?: NotificationFormatterModel[],
}

const initialState: SliceState = {
    error: undefined,
    clientPagingResult: undefined,
    client: undefined,
    clientContactInfo: undefined,
    tradingPartners: undefined,
    clientNotes: undefined,
    clientAttachments: undefined,
    clientAKAs: undefined,
    clientTransactions: undefined,
    upsertClientStatus: undefined,
    deleteClientStatus: undefined,
    upsertClientNoteStatus: undefined,
    deleteClientNoteStatus: undefined,
    upsertClientAttachmentsStatus: undefined,
    deleteClientAttachmentStatus: undefined,
    upsertClientAkaStatus: undefined,
    deleteClientAkaStatus: undefined,
    upsertClientContactStatus: undefined,
    deleteClientContactStatus: undefined,
    upsertClientContactNoteStatus: undefined,
    deleteClientContactNoteStatus: undefined,
    addClientPartnerStatus: undefined,
    attachmentsPagingResult: undefined,
    upsertEdiXmlConfigStatus: undefined,
    deleteEdiXmlConfigStatus: undefined,
    upsertClientTransactionStatus: undefined,
    deleteClientTransactionStatus: undefined,
    transactions: undefined,
    deleteClientPartnerStatus: undefined,
    billingAggregates: undefined,
    clientNotificationRecipients: undefined,
    upsertClientNotificationRecipientsStatus: undefined,
    deleteClientNotificationRecipientsStatus: undefined,
    notificationFormatters: undefined,
};

export const slice = createSlice({
    name: 'clientDetails',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.clientPagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.client = undefined;
            // eslint-disable-next-line no-param-reassign
            state.clientContactInfo = undefined;
            // eslint-disable-next-line no-param-reassign
            state.tradingPartners = undefined;
            // eslint-disable-next-line no-param-reassign
            state.clientNotes = undefined;
            // eslint-disable-next-line no-param-reassign
            state.clientAttachments = undefined;
            // eslint-disable-next-line no-param-reassign
            state.clientAKAs = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertClientContactStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteClientContactStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertClientStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteClientStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.addClientPartnerStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertClientAttachmentsStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteClientAttachmentStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertClientAkaStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteClientAkaStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.attachmentsPagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertClientContactNoteStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteClientContactNoteStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertEdiXmlConfigStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteEdiXmlConfigStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.clientTransactions = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertClientTransactionStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteClientTransactionStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteClientPartnerStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.transactions = undefined;
            // eslint-disable-next-line no-param-reassign
            state.billingAggregates = undefined;
            // eslint-disable-next-line no-param-reassign
            state.clientNotificationRecipients = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertClientNotificationRecipientsStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteClientNotificationRecipientsStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.notificationFormatters = undefined;
        },

        fetchClientByIdSuccess: (state, action: PayloadAction<PagingResultModelOfClientModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.clientPagingResult = action.payload;
            //const totalCount = action.payload.totalCount;
            //if (totalCount > 0) {
            if (action.payload.nodes && action.payload.nodes[0]) {
                let client = action.payload.nodes[0];
                if (client) {
                    // eslint-disable-next-line no-param-reassign
                    state.client = client;
                    if (client.clientContacts && client.clientContacts.length > 0) {
                        // eslint-disable-next-line no-param-reassign
                        state.clientContactInfo = client.clientContacts as ClientContactModel[];
                        // If we want to clientside sort by name, and names become required properties
                        /*if (state.clientContactInfo.length) {
                            state.clientContactInfo.sort((a, b) => (a.name > b.name) ? 1 : -1)
                        }*/
                    }
                    if (client.clientPartners && client.clientPartners.length > 0) {
                        // eslint-disable-next-line no-param-reassign
                        state.tradingPartners = client.clientPartners as ClientPartnerModel[];
                        if (state.tradingPartners.length) {
                            state.tradingPartners.sort((a, b) => ((a.partner?.name ?? '') > (b.partner?.name ?? '')) ? 1 : -1)
                        }
                    } else {
                        state.tradingPartners = undefined;
                    }
                    if (client.notes) {
                        // eslint-disable-next-line no-param-reassign
                        state.clientNotes = client.notes as NoteModel[];
                    }
                    if (client.attachments) {
                        // eslint-disable-next-line no-param-reassign
                        state.clientAttachments = client.attachments as AttachmentModel[];
                    }
                }
            }
            //}
        },

        captureUpsertClientStatus: (state, action: PayloadAction<UpsertClientPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertClientStatus = action.payload;
        },

        captureDeleteClientStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteClientStatus = action.payload;
        },

        captureUpsertClientNoteStatus: (state, action: PayloadAction<UpsertClientPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertClientNoteStatus = action.payload;
        },

        captureDeleteClientNoteStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteClientNoteStatus = action.payload;
        },

        captureUpsertClientAkaStatus: (state, action: PayloadAction<UpsertClientAkaPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertClientAkaStatus = action.payload;
        },

        captureDeleteClientAkaStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteClientAkaStatus = action.payload;
        },

        captureUpsertClientAttachmentsStatus: (state, action: PayloadAction<UpsertClientAttachmentPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertClientAttachmentsStatus = action.payload;
        },

        captureDeleteClientAttachmentStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteClientAttachmentStatus = action.payload;
        },
        captureUpsertClientContactStatus: (state, action: PayloadAction<UpsertClientContactPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertClientContactStatus = action.payload;
        },

        captureDeleteClientContactStatus: (state, action: PayloadAction<DeleteClientContactPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteClientContactStatus = action.payload;
        },

        captureUpsertClientContactNoteStatus: (state, action: PayloadAction<UpsertClientContactPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertClientContactNoteStatus = action.payload;
        },

        captureDeleteClientContactNoteStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteClientContactNoteStatus = action.payload;
        },

        captureAddClientPartnerStatus: (state, action: PayloadAction<AddClientPartnerPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.addClientPartnerStatus = action.payload;
        },

        fetchClientAttachmentSuccess: (state, action: PayloadAction<PagingResultModelOfAttachmentModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.attachmentsPagingResult = action.payload.nodes as AttachmentModel[];
        },

        clearClientAttachment: state => {
            // eslint-disable-next-line no-param-reassign
            state.attachmentsPagingResult = undefined;
        },

        captureUpsertEdiXmlConfigSuccess: (state, action: PayloadAction<UpsertEdiXmlConfigPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertEdiXmlConfigStatus = action.payload;
        },

        captureDeleteEdiXmlConfigStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteEdiXmlConfigStatus = action.payload;
        },

        fetchClientTransactionsSuccess: (state, action: PayloadAction<ClientTransactionModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.clientTransactions = action.payload;
        },

        captureUpsertClientTransactionStatus: (state, action: PayloadAction<UpsertClientTransactionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertClientTransactionStatus = action.payload
        },

        captureDeleteClientTransactionStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteClientTransactionStatus = action.payload;
        },

        fetchTransactionsSuccess: (state, action: PayloadAction<TransactionModel[] | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.transactions = action.payload;
        },

        captureDeleteClientPartnerStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteClientPartnerStatus = action.payload;
        },

        fetchBillingAggregatesSuccess: (state, action: PayloadAction<BillingAggregatesModel[] | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.billingAggregates = action.payload;
        },

        fetchClientNotificationRecipientsSuccess: (state, action: PayloadAction<ClientNotificationRecipientsModel[] | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.clientNotificationRecipients = action.payload;
        },

        captureUpsertClientNotificationRecipientsStatus: (state, action: PayloadAction<UpsertClientNotificationRecipientsPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertClientNotificationRecipientsStatus = action.payload;
        },

        captureDeleteClientNotificationRecipientsStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteClientNotificationRecipientsStatus = action.payload;
        },

        fetchNotificationFormattersSuccess: (state, action: PayloadAction<NotificationFormatterModel[] | undefined>) => {
            let formatters = action.payload;
            if (formatters && formatters.length) {
                formatters.sort((a, b) => ((a.description ?? '') > (b.description ?? '')) ? 1 : -1)
            }
            // eslint-disable-next-line no-param-reassign
            state.notificationFormatters = formatters;
        },
    }
});

export const selectError = (state: RootState): Error | undefined => state.clientDetails.error;
export const selectClientPagingResult = (state: RootState): PagingResultModelOfClientModel | undefined => state.clientDetails.clientPagingResult;
export const selectClient = (state: RootState): ClientModel | undefined => state.clientDetails.client;
export const selectContactInfo = (state: RootState): ClientContactModel[] | undefined => state.clientDetails.clientContactInfo;
export const selectTradingPartners = (state: RootState): ClientPartnerModel[] | undefined => state.clientDetails.tradingPartners;
export const selectClientNotes = (state: RootState): NoteModel[] | undefined => state.clientDetails.clientNotes;
export const selectClientAttachments = (state: RootState): AttachmentModel[] | undefined => state.clientDetails.clientAttachments;
export const selectUpsertClientStatus = (state: RootState): UpsertClientPayload | undefined => state.clientDetails.upsertClientStatus;
export const selectDeleteClientStatus = (state: RootState): DeleteByIdPayload | undefined => state.clientDetails.deleteClientStatus;
export const selectUpsertClientNoteStatus = (state: RootState): UpsertClientPayload | undefined => state.clientDetails.upsertClientNoteStatus;
export const selectDeleteClientNoteStatus = (state: RootState): DeleteByIdPayload | undefined => state.clientDetails.deleteClientNoteStatus;
export const selectUpsertClientAkaStatus = (state: RootState): UpsertClientAkaPayload | undefined => state.clientDetails.upsertClientAkaStatus;
export const selectDeleteClientAkaStatus = (state: RootState): DeleteByIdPayload | undefined => state.clientDetails.deleteClientAkaStatus;
export const selectUpsertClientContactStatus = (state: RootState): UpsertClientContactPayload | undefined => state.clientDetails.upsertClientContactStatus;
export const selectDeleteClientContactStatus = (state: RootState): DeleteClientContactPayload | undefined => state.clientDetails.deleteClientContactStatus;
export const selectUpsertClientContactNoteStatus = (state: RootState): UpsertClientContactPayload | undefined => state.clientDetails.upsertClientContactNoteStatus;
export const selectDeleteClientContactNoteStatus = (state: RootState): DeleteByIdPayload | undefined => state.clientDetails.deleteClientContactNoteStatus;
export const selectAddClientPartnerStatus = (state: RootState): AddClientPartnerPayload | undefined => state.clientDetails.addClientPartnerStatus;
export const selectUpsertClientAttachmentsStatus = (state: RootState): UpsertClientAttachmentPayload | undefined => state.clientDetails.upsertClientAttachmentsStatus;
export const selectDeleteClientAttachmentStatus = (state: RootState): DeleteByIdPayload | undefined => state.clientDetails.deleteClientAttachmentStatus;
export const selectAttachmentToDownload = (state: RootState): AttachmentModel[] | undefined => state.clientDetails.attachmentsPagingResult;
export const selectUpsertEdiXmlConfigStatus = (state: RootState): UpsertEdiXmlConfigPayload | undefined => state.clientDetails.upsertEdiXmlConfigStatus;
export const selectDeleteEdiXmlConfigStatus = (state: RootState): DeleteByIdPayload | undefined => state.clientDetails.deleteEdiXmlConfigStatus;
export const selectClientTransactions = (state: RootState): ClientTransactionModel[] | undefined => state.clientDetails.clientTransactions;
export const selectUpsertClientTransactionStatus = (state: RootState): UpsertClientTransactionPayload | undefined => state.clientDetails.upsertClientTransactionStatus;
export const selectDeleteClientTransactionStatus = (state: RootState): DeleteByIdPayload | undefined => state.clientDetails.deleteClientTransactionStatus;
export const selectEligibleTransactions = (state: RootState): TransactionModel[] | undefined => state.clientDetails.transactions;
export const selectDeleteClientPartnerStatus = (state: RootState): DeleteByIdPayload | undefined => state.clientDetails.deleteClientPartnerStatus;
export const selectBillingAggregates = (state: RootState): BillingAggregatesModel[] | undefined => state.clientDetails.billingAggregates;
export const selectClientNotificationsRecipients = (state: RootState): ClientNotificationRecipientsModel[] | undefined => state.clientDetails.clientNotificationRecipients;
export const selectUpsertClientNotificationsRecipientsStatus = (state: RootState): UpsertClientNotificationRecipientsPayload | undefined => state.clientDetails.upsertClientNotificationRecipientsStatus;
export const selectDeleteClientNotificationsRecipientsStatus = (state: RootState): DeleteByIdPayload | undefined => state.clientDetails.deleteClientNotificationRecipientsStatus;
export const selectEligibleNotificationFormatters = (state: RootState): NotificationFormatterModel[] | undefined => state.clientDetails.notificationFormatters;


// since contact notes is a list in the contact dialog and it has a separate save from contact,
// this select will be used in a selector to refresh the notes list on the given contact
export const selectContactNotesByClientContactId = (id: string | undefined) => (state: RootState): NoteModel[] | undefined => {
    if (id) {
        let contacts = state?.clientDetails?.clientContactInfo?.filter((c) => c.id === id);
        if (contacts && contacts.length && contacts[0] && contacts[0].notes) {
            return contacts[0].notes as NoteModel[];
        }
    }
    return undefined;
};

export const {
    clearError,
    clearState,
    fetchError,
    fetchClientByIdSuccess,
    fetchClientTransactionsSuccess,
    captureUpsertClientContactStatus,
    captureDeleteClientContactStatus,
    captureUpsertClientStatus,
    captureDeleteClientStatus,
    captureUpsertClientNoteStatus,
    captureDeleteClientNoteStatus,
    captureUpsertClientContactNoteStatus,
    captureDeleteClientContactNoteStatus,
    captureAddClientPartnerStatus,
    captureUpsertClientAttachmentsStatus,
    captureDeleteClientAttachmentStatus,
    fetchClientAttachmentSuccess,
    clearClientAttachment,
    captureUpsertEdiXmlConfigSuccess,
    captureDeleteEdiXmlConfigStatus,
    captureUpsertClientTransactionStatus,
    captureDeleteClientTransactionStatus,
    fetchTransactionsSuccess,
    captureUpsertClientAkaStatus,
    captureDeleteClientAkaStatus,
    captureDeleteClientPartnerStatus,
    fetchBillingAggregatesSuccess,
    fetchClientNotificationRecipientsSuccess,
    captureDeleteClientNotificationRecipientsStatus,
    captureUpsertClientNotificationRecipientsStatus,
    fetchNotificationFormattersSuccess
} = slice.actions;

export default slice.reducer;